import { render as Render, createPortal, unmountComponentAtNode as UnMount } from 'react-dom'
import { ToastDom } from './toastDom'
import './style.scss'
const hideAinDuration = 140
class XToast {
  constructor() {
    this.show = (msg, config) => {
      const toastCtr = document.createElement('div')
      toastCtr.className = 'x-toast-outer'
      let show = true
      let timer = null
      const { duration = 3, btn, className, id } = config || {}
      if (className) {
        toastCtr.className = `${toastCtr.className} ${className}`
      }
      if (id) {
        toastCtr.id = id
      }
      // 定时器清除
      const clearTimer = () => {
        if (timer) {
          clearTimeout(timer)
          timer = null
        }
      }
      // 关闭动画 内存清理
      const handleClose = () => {
        clearTimer()
        show = false
        toastCtr.className = 'x-toast-outer x-toast-hide'
        setTimeout(() => {
          UnMount(toastCtr)
          toastCtr.onmouseenter = null
          toastCtr.onmouseleave = null
          toastCtr.remove()
        }, hideAinDuration)
      }
      // 自动关闭
      const autoClose = () => {
        timer = setTimeout(() => {
          timer = null
          if (show) {
            handleClose()
          }
        }, duration * 1000)
      }
      // 鼠标划入 清除定时关闭功能
      toastCtr.onmouseenter = () => {
        clearTimer()
      }
      // 鼠标离开入 开启定时关闭功能
      toastCtr.onmouseleave = () => {
        autoClose()
      }
      Render(
        createPortal(<ToastDom msg={msg} btn={btn} handleClose={handleClose} />, toastCtr),
        toastCtr,
      )
      this.toastArea.appendChild(toastCtr)
      // toastCtr.style.height = toastCtr.offsetHeight + 'px';
      autoClose()
      return handleClose
    }
    this.success = (msg) => {
      this.show({
        title: 'Success',
        txt: msg,
        type: 'success',
      })
    }
    this.error = (msg) => {
      this.show({
        title: 'Error',
        txt: msg,
        type: 'error',
      })
    }
    const toastArea = document.getElementById('xToastArea-top')
    if (toastArea) {
      this.toastArea = toastArea
    } else {
      this.toastArea = document.createElement('div')
      this.toastArea.id = 'xToastArea-top'
      document.body.appendChild(this.toastArea)
    }
  }
}
const StretchToast = new XToast()
export default StretchToast
