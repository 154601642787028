import getData from '../services/getData'
import { deepCopy, getQuery } from '@/components/trade/utils/funcs'
import { getCurrentLang, langList } from '@/config/lang'
import { getStoreItem, setStoreItem } from '@/utils/globalLocalStore'
import { KLINE_LOCAL_KEY, themeColorList, themeTypeList } from '@/components/trade/constant'
import { depositVersion } from '@/utils/env'
export let depositConfigEnum
;(function (depositConfigEnum) {
  depositConfigEnum['usdt'] = 'usdt'
  depositConfigEnum['usdc'] = 'usdc'
})(depositConfigEnum || (depositConfigEnum = {}))
const initState = {
  themeType: 'v1',
  themeColor: 'normal',
  kLineConfig: {
    showPositionLine: true,
    showOrderLine: true,
    showTpOSlLine: true,
    showConditionalLine: true,
    quickTrading: true,
  },
  userinfo: {
    account: {
      accountNumber: '',
      adminSignature: '',
      createdAt: '',
      id: '',
      openPositions: '',
      pendingDeposits: '',
      pendingWithdrawals: '',
      positionId: '',
      quoteBalance: '',
      starkKey: '',
      wallets: '',
      accounts: [],
    },
    apiKey: {
      key: '',
      passphrase: '',
      secret: '',
    },
    user: {
      apexTokenBalance: '',
      country: '',
      email: '',
      emailNotifyGeneralEnable: false,
      emailNotifyTradingEnable: false,
      emailNotifyAccountEnable: false,
      ethereumAddress: '',
      fees30D: '',
      id: '',
      isEmailVerified: '',
      isRegistered: '',
      isSharingAddress: '',
      isSharingUsername: '',
      makerFeeRate: '',
      makerVolume30D: '',
      referredByAffiliateLink: '',
      stakedApexTokenBalance: '',
      takerFeeRate: '',
      takerVolume30D: '',
      userData: {},
      username: '',
    },
  },
  global: {},
  multiChain: {
    chains: [],
    currency: 'USDC',
    maxWithdraw: '1000000',
    minDeposit: '10',
    minWithdraw: '10',
  },
  multiConfig: {
    usdt: {
      chains: [],
      currency: 'USDC',
      maxWithdraw: '1000000',
      minDeposit: '10',
      minWithdraw: '10',
    },
    usdc: {
      chains: [],
      currency: 'USDT',
      maxWithdraw: '1000000',
      minDeposit: '10',
      minWithdraw: '10',
    },
  },
  userinfo_loading: false,
  keyPair: {
    publicKey: '',
    privateKey: '',
    publicKeyYCoordinate: '',
  },
  balance: {
    accountNumber: '',
    adminSignature: '',
    createdAt: '',
    id: '',
    openPositions: null,
    pendingDeposits: '',
    pendingWithdrawals: '',
    positionId: '',
    quoteBalance: '',
    starkKey: '',
    wallets: null,
  },
  account: '',
  active: false,
  activating: !!window.localStorage.walletway,
  activate: () => {},
  deactivate: () => {},
  createStartKey: () => {},
  globalConnectWallet: () => {},
  lang: getCurrentLang(),
  langList,
  symbols: {
    'BTC-USD': {
      symbol: 'BTC-USD',
      pricePrecision: 0,
      priceStep: 1,
      sizePrecision: 4,
      sizeStep: 0.0001,
      minOrderSize: 0.0001,
      baseCoin: 'USDC',
      currentCoin: 'BTC',
      digitMerge: '1,10,100,1000',
    },
  },
  forceOpenDeposit: false,
  isLoadGuidance: true,
  depositConfig: depositConfigEnum.usdc,
}

export default {
  namespace: 'layout',
  state: initState,
  subscriptions: {
    setup({ dispatch }) {
      // 查询deposit存储版本
      const dv = getStoreItem('deposit-version')
      if (!dv || dv !== depositVersion) {
        window.localStorage.removeItem('deposit-tx')
        setStoreItem('deposit-version', depositVersion)
      }
      const urlLang = getQuery('lang', window.location.href)
      // kol && code 都代表邀请码
      const kol = getQuery('kol', window.location.href)
      const code = getQuery('code', window.location.href)
      const emailVerifyUserId = getQuery('userId', window.location.href)
      const emailVerifyToken = getQuery('token', window.location.href)
      const affiliateId = getQuery('affiliate_id', window.location.href)
      const groupId = getQuery('group_id', window.location.href)
      const experienceMoneyId = getQuery('experienceMoneyId', window.location.href)
      const agentMVPId = getQuery('agentMVPId', window.location.href)
      const MVPId = getQuery('MVPId', window.location.href)
      setStoreItem('invitingUrl', window.location.href)
      if (urlLang) {
        setStoreItem('lang', urlLang)
      }
      if (kol || code) {
        setStoreItem('kol', kol ?? code)
      }
      if (emailVerifyUserId) {
        setStoreItem('emailVerifyUserId', emailVerifyUserId)
      }
      if (emailVerifyToken) {
        setStoreItem('emailVerifyToken', emailVerifyToken)
      }
      if (affiliateId) {
        setStoreItem('affiliateId', affiliateId)
      }
      if (groupId) {
        setStoreItem('groupId', groupId)
      }
      if (experienceMoneyId) {
        setStoreItem('experienceMoneyId', experienceMoneyId)
      }
      if (agentMVPId) {
        setStoreItem('agentMVPId', agentMVPId)
      }
      if (MVPId) {
        setStoreItem('MVPId', MVPId)
      }

      // 获取当前主题
      let themeType = getStoreItem('themeType')
      themeType = themeTypeList.includes(themeType) ? themeType : 'v1'
      let themeColor = getStoreItem('themeColor')
      themeColor = themeColorList.includes(themeColor) ? themeColor : 'normal'
      document.documentElement.className = `${themeType}-${themeColor}`
      const hasResetTV = Number(getStoreItem('clearLocalTV') || 0)
      if (hasResetTV < 1) {
        localStorage.removeItem(KLINE_LOCAL_KEY)
        setStoreItem('clearLocalTV', '1')
      }
      // 获取K线显示设置
      const localKLineConfig = getStoreItem('kLineConfig')
      if (localKLineConfig) {
        dispatch({
          type: 'setKLineConfig',
          payload: localKLineConfig,
        })
      }
      dispatch({
        type: 'setThemeType',
        payload: themeType,
      })
      dispatch({
        type: 'setThemeColor',
        payload: themeColor,
      })
    },
  },
  effects: {
    // 查询用户信息
    *userinfo({ payload }, { call, put }) {
      const res = yield call(getData('userinfo'), {
        payload,
        method: 'get',
      })
      if (res.code == 200) {
        yield put({
          type: 'save',
          payload: {
            userinfo: {},
          },
        })
      }
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload }
    },
    updateSymbols(state, action) {
      const instrumentInfoAll = action.payload
      const symbols = deepCopy(state.symbols)
      const symbolNameList = Object.keys(symbols)
      if (symbolNameList.length) {
        symbolNameList.forEach((symbolName) => {
          const instrumentSymbol = symbols[symbolName].crossSymbolName || ''
          const symbolInfo = instrumentInfoAll[instrumentSymbol] || {}
          delete symbolInfo.symbol
          symbols[symbolName] = { ...symbols[symbolName], ...symbolInfo }
        })
      }
      return { ...state, ...{ symbols } }
    },
    setThemeType(state, action) {
      const type = action.payload
      setStoreItem('themeType', type)
      setStoreItem('lastThemeName', `${state.themeType}-${state.themeColor}`)
      document.documentElement.className = `${type}-${state.themeColor}`
      return { ...state, ...{ themeType: action.payload } }
    },
    setThemeColor(state, action) {
      const color = action.payload
      setStoreItem('themeColor', color)
      setStoreItem('lastThemeName', `${state.themeType}-${state.themeColor}`)
      document.documentElement.className = `${state.themeType}-${color}`
      return { ...state, ...{ themeColor: action.payload } }
    },
    setKLineConfig(state, action) {
      const newConfig = action.payload
      const tempConfig = { ...state.kLineConfig, ...newConfig }
      setStoreItem('kLineConfig', tempConfig)
      return { ...state, ...{ kLineConfig: tempConfig } }
    },
  },
}
