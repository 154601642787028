import instance from './instance'

/**
 * 获取用户拥有的代币列表
 * @param {string} address - 用户地址
 * @returns {Promise<Array<object>>} 代币列表
 * @example
 * // 返回示例
 * [
 *   {
 *     tokenId: "0xac4f7b5b420289bb4d12e5115cfb63c114855b8c65249c46297adf502af02e9d",
 *     ticker: "MASON",
 *     amount: "1000",
 *     data: "data:,{\"p\":\"nip-20\",\"op\":\"mint\",\"tick\":\"MASON\",\"id\":1,\"amt\":1000}"
 *   },
 *   // 更多代币...
 * ]
 * 
 * @property {string} tokenId - 代币 ID
 * @property {string} ticker - 代币符号
 * @property {string} amount - 代币数量
 * @property {string} data - 代币数据
 */
export async function fetchOwnedTokens(address) {
  return instance.get('/tokens/ownedBy', {
    params: {
      address,
    },
  }).then((res) => res.ownedTokens).catch(() => [])
}

/**
 * 获取所有代币列表
 * @returns {Promise<Array<object>>} 代币列表
 * @example
 * // 返回示例
 * [
 *   {
 *     ticker: "MASON",
 *     maxamount: "1000000000",
 *     mint_limit: "1000",
 *     data: "data:,{\"p\":\"nip-20\",\"op\":\"deploy\",\"tick\":\"Mason\",\"max\":\"1000000000\",\"lim\":\"1000\",\"contract\":\"\",\"cmint\":\"false\"}",
 *     block_number: "834643"
 *   }
 *   // 更多代币...
 * ]
 * 
 * @property {string} ticker - 代币符号
 * @property {string} maxamount - 最大发行量
 * @property {string} mint_limit - 单次铸造限额
 * @property {string} data - 代币部署数据
 * @property {string} block_number - 部署区块高度
 */
export async function fetchListingTokens() {
  return instance.get('/token/gettokens').then((res) => res.tokens).catch(() => [])
}
