import { CHAIN_INFO, DEFAULT_CHAIN_ID, ifCountdownMode } from '@/components/public/apex/constants'
import Icon from '@/components/public/icon'
import { formatSeconds } from '@/components/trade/utils/funcs'
import useInterval from '@/hooks/useInterval'
import { useLanguage } from '@/language'
import { Grid, LinearProgress } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import './index.scss'

const ConfirmationToast = ({
  provider,
  max,
  tx,
  chainId,
  usdcValue,
  handleClose,
  symbol,
  lightMode,
  toggleIcon,
  toggleLight,
  setActiveTitle,
  toggleDefault,
  removeArr,
  maxSec,
}) => {
  const multiLanguage = useLanguage()
  const [currentConfirmation, setCurrentConfirmation] = useState(0)
  const [ifFinished, setFinished] = useState(false)
  const txHash = useRef(tx)
  const value = useRef(usdcValue)
  const currentSymbol = useRef(symbol)
  const { chainId: currentChainId } = useWeb3React()
  const [valid, setValid] = useState(true)
  // const countDown = useCountdown(blockTime ? new Date(blockTime) : new Date(99999999999));
  const intervalCallback = async () => {
    if (ifCountdownMode) return
    if (!provider) return
    let p = provider
    if (chainId && chainId !== currentChainId) {
      p = new ethers.JsonRpcProvider(CHAIN_INFO[chainId].rpc)
    }
    const txs = await p.getTransaction(txHash?.current)
    try {
      const status = await txs.wait()
      if (status?.status === 1) {
        setCurrentConfirmation((old) => {
          if (txs?.confirmations && new BigNumber(txs?.confirmations).gt(old)) {
            return txs?.confirmations
          }
          return old
        })
      } else {
        setValid(false)
        removeArr && removeArr()
      }
    } catch (e) {
      if (e.message.startsWith('transaction failed')) {
        setValid(false)
        removeArr && removeArr()
      }
    }
  }
  const interval = useMemo(() => {
    if (ifCountdownMode) {
      return null
    }
    if (!valid) {
      setFinished(true)
      return null
    }
    if (new BigNumber(currentConfirmation).gte(max) || !provider) {
      setFinished(true)
      // handleClose && handleClose();
      return null
    }
    return 3000
  }, [currentConfirmation, max, provider, valid, ifCountdownMode])
  const progress = useMemo(() => {
    // if (ifCountdownMode) {
    //   return new BigNumber(maxSec - countDown).div(maxSec).multipliedBy(100);
    // }
    return new BigNumber(currentConfirmation).div(max).multipliedBy(100)
  }, [currentConfirmation, max])
  // useEffect(() => {
  //   if (!countDown && ifCountdownMode) {
  //     setFinished(true);
  //   }
  // }, [countDown, ifCountdownMode]);
  useEffect(() => {
    if (ifFinished) {
      toggleDefault()
      toggleIcon('success')
      setActiveTitle(
        chainId === DEFAULT_CHAIN_ID
          ? multiLanguage('AssetActionNotification.title13')
          : multiLanguage('AssetActionNotification.title14'),
      )
      setTimeout(() => {
        handleClose && handleClose()
      }, 10000)
    }
  }, [ifFinished])
  useEffect(() => {
    if (ifFinished && !valid) {
      toggleDefault()
      toggleIcon('error')
      setTimeout(() => {
        handleClose && handleClose()
      }, 10000)
    }
  }, [ifFinished, valid])
  useInterval(intervalCallback, interval, true)
  if (ifFinished && valid) {
    return (
      <div className="confirmationToast">
        {chainId === DEFAULT_CHAIN_ID ? (
          <Trans
            i18nKey={'AssetActionNotification.Content13WithAssets'}
            values={{ coin: currentSymbol.current, amount: value.current }}
            components={[
              <span style={{ fontWeight: 600, color: '#fff' }}>
                {usdcValue} {symbol}
              </span>,
            ]}
          >
            Ethereum Deposit of{' '}
            <span style={{ fontWeight: 600, color: '#fff' }}>
              {usdcValue} {symbol}
            </span>{' '}
            has been confirmed and can be viewed in Assets Overview shortly
          </Trans>
        ) : (
          <Trans
            i18nKey={'AssetActionNotification.Content14WithAssets'}
            values={{
              chainName: chainId ? CHAIN_INFO[chainId]?.chainName : 'unknown',
              amount: value.current,
              coin: currentSymbol.current,
            }}
            components={[
              <span style={{ fontWeight: 600, color: '#fff' }}>
                {usdcValue} {symbol}
              </span>,
            ]}
          >
            Non-Ethereum Deposit of{' '}
            <span style={{ fontWeight: 600, color: '#fff' }}>
              {usdcValue} {symbol}
            </span>{' '}
            has been confirmed and can be viewed in Assets Overview shortly
          </Trans>
        )}
      </div>
    )
  }
  if (ifFinished && !valid) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#fff',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
        }}
        className="confirmationToast"
      >
        {multiLanguage('deposit.fail')}
        <span>
          {chainId && (
            <a
              target="_blank"
              style={{ color: '#FFC412', marginLeft: '6px' }}
              href={CHAIN_INFO[chainId].explorer + `/tx/${txHash?.current}`}
            >
              {multiLanguage('seeDetails')}
            </a>
          )}
        </span>
      </div>
    )
  }
  if (lightMode) {
    return (
      <div
        onClick={() => {
          if (!ifFinished) {
            toggleDefault()
          }
        }}
        style={{ display: 'flex', alignItems: 'center' }}
        className="confirmationToast"
      >
        <span
          style={{
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#fff',
          }}
        >
          {multiLanguage('depositing')}
        </span>

        <div className="f-14 default" style={{ margin: '0 8px 0 4px' }}>
          ~{formatSeconds(maxSec)?.minute}m{formatSeconds(maxSec)?.second}s
          {/* ≈{formatSeconds(maxSec)?.minute}:{formatSeconds(maxSec)?.second} */}
        </div>

        <LinearProgress
          className="progress-bar"
          style={{ width: '105px' }}
          variant="determinate"
          value={progress.toNumber()}
        />

        <span style={{ marginLeft: '12px', display: 'flex', alignItems: 'center' }}>
          <Icon onClick={toggleDefault} name="ArrowDown" className="mode-icon" />
        </span>
      </div>
    )
  }
  return (
    <Grid className="confirmationToast" container flexDirection="column" alignItems="flex-start">
      <div className="f-14 default">
        {chainId && chainId === DEFAULT_CHAIN_ID ? (
          <Trans
            i18nKey={'AssetActionNotification.Content1WithAssets'}
            values={{ amount: value.current, coin: currentSymbol.current }}
            components={[
              <span style={{ fontWeight: 600, color: '#fff' }}>
                {usdcValue} {symbol}
              </span>,
            ]}
          >
            Ethereum Deposit of{' '}
            <span style={{ fontWeight: 600, color: '#fff' }}>
              {usdcValue} {symbol}
            </span>{' '}
            is in progress
          </Trans>
        ) : (
          <Trans
            i18nKey={'AssetActionNotification.Content3WithAssets'}
            values={{
              chainName: chainId ? CHAIN_INFO[chainId]?.chainName : 'unknown',
              amount: value.current,
              coin: currentSymbol.current,
            }}
            components={[
              <span style={{ fontWeight: 600, color: '#fff' }}>
                {usdcValue} {symbol}
              </span>,
            ]}
          >
            Non-Ethereum Deposit of{' '}
            <span style={{ fontWeight: 600, color: '#fff' }}>
              {usdcValue} {symbol}
            </span>{' '}
            from {chainId ? CHAIN_INFO[chainId]?.chainName : 'unknown'} is in progress.
          </Trans>
        )}
      </div>
      <LinearProgress
        className="progress-bar"
        style={{ width: '100%' }}
        variant="determinate"
        value={progress.toNumber()}
      />
      <div className="f-14 default justify-content-between">
        <span>
          ~{formatSeconds(maxSec)?.minute}m{formatSeconds(maxSec)?.second}s
        </span>
        {/* ≈{formatSeconds(maxSec)?.minute}:{formatSeconds(maxSec)?.second} */}
        {/* {multiLanguage('depositconfirmationProgress')} */}
        <Icon onClick={toggleLight} name="ArrowUp" className="mode-icon" />
      </div>
    </Grid>
  )
}
export default ConfirmationToast
