import Big from 'bignumber.js'
// 保留小数点 36 位
Big.DP = 36

export const nuvoLogin = async () => {
  const oauth2 = document.querySelector('#Oauth2')
  oauth2.style.display = 'flex'
}

export const nuvo = {
  firstUpper(s) {
    return s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase()
  },

  formatUnits(value, decimals = 18) {
    let result = '0'
    try {
      result = this.div(value, new Big(10).pow(decimals))
    } catch (error) {
      console.error('formatUnits', error)
    }
    return result
  },

  parseUnits(value, decimals = 18) {
    let result = '0'
    try {
      result = this.mul(value, new Big(10).pow(decimals))
    } catch (error) {
      console.error('parseUnits', error)
    }
    return result
  },

  usd(n = 0) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    return formatter.format(n)
  },

  // 乘法 multiply
  mul(a = 0, b = 0) {
    try {
      a = new Big(a)
      b = new Big(b)
      const total = a.times(b)
      return total.toFixed()
    } catch (error) {
      return 0
    }
  },

  // 加法 add
  add(a = 0, b = 0) {
    try {
      a = new Big(a)
      b = new Big(b)
      const total = a.plus(b)
      return total.toFixed()
    } catch (error) {
      return 0
    }
  },

  // 减法 subtract
  sub(a = 0, b = 0) {
    try {
      a = new Big(a)
      b = new Big(b)
      const total = a.minus(b)
      return total.toFixed()
    } catch (error) {
      return 0
    }
  },

  // 除法 divide
  div(a = 0, b = 1, decimals) {
    try {
      a = new Big(a)
      b = new Big(b)
      if (b.eq(0)) {
        throw new Error('Division by zero')
      }
      const total = a.div(b)
      return this.toFixed(total, decimals)
    } catch (error) {
      return 0
    }
  },
  toFixed(v, decimals) {
    let n = new Big(v)
    n = new Big(n.toFixed(decimals))
    return new Big(n).toFixed()
  },
  // 小于 <
  lt(a = 0, b = 0) {
    a = new Big(a)
    b = new Big(b)
    return a.lt(b)
  },
  // 小于 <=
  lte(a = 0, b = 0) {
    a = new Big(a)
    b = new Big(b)
    return a.lte(b)
  },
  // 大于 >
  gt(a = 0, b = 0) {
    a = new Big(a)
    b = new Big(b)
    return a.gt(b)
  },
  // 大于 <=
  gte(a = 0, b = 0) {
    a = new Big(a)
    b = new Big(b)
    return a.gte(b)
  },
  // 保留小数，截断
  truncateDecimalPlaces(value, decimal) {
    value = new Big(value)
    return value.decimalPlaces(decimal, Big.ROUND_DOWN).toFixed()
  },
}
