export let WalletWay
;(function (WalletWay) {
  WalletWay['MetaMask'] = 'injected'
  WalletWay['CoinbaseWallet'] = 'coinbaseWallet'
  WalletWay['Walletconnect'] = 'walletConnect'
  WalletWay['OkexWallet'] = 'okexWallet'
  WalletWay['BitkeepWallet'] = 'bitgetWallet'
  WalletWay['TrustWallet'] = 'trustWallet'
})(WalletWay || (WalletWay = {}))
export let ConnectStatus
;(function (ConnectStatus) {
  ConnectStatus['Success'] = 'success'
  ConnectStatus['Progress'] = 'progress'
  ConnectStatus['Error'] = 'error'
})(ConnectStatus || (ConnectStatus = {}))
// 币对信息
let symbols = []
let currentConfig = 'usdc'
const setCurrentConfig = (data) => {
  currentConfig = data
}
const getCurrentConfig = () => {
  return currentConfig
}
const setSymbols = (data) => {
  symbols = data
}
const getSymbols = () => {
  return symbols
}
// 价值信息
let currency = {
  usdt: [],
  usdc: [],
}
const setCurrency = (data) => {
  currency = data
}
const getCurrency = (config) => {
  const curConfig = getCurrentConfig()
  const _config = config || curConfig
  return currency[_config]
}
// config
let config = {
  usdt: {},
  usdc: {},
}
const setConfig = (data) => {
  config = data
}
const getConfig = (forceConfig) => {
  const curConfig = getCurrentConfig()
  const _config = forceConfig || curConfig
  return config[_config]
}
export {
  setCurrentConfig,
  getCurrentConfig,
  setSymbols,
  getSymbols,
  setCurrency,
  getCurrency,
  setConfig,
  getConfig,
}
