import { useTranslation } from 'react-i18next'
// import { isGray, isProd } from '@/utils/env'
import { getCurrentLang } from '@/config/lang'
import { setStoreItem } from '@/utils/globalLocalStore'
// const envStr = isGray ? '-gray' : isProd ? '' : '-qa'
// const defaultLang = 'en'
export async function getLangMap(lang) {
  switch (lang) {
    case 'zh-CN':
      return require('@/language/lanFiles/zh-CN.json')
    case 'zh-TW':
      return require('@/language/lanFiles/zh-TW.json')
    case 'en':
    default:
      return require('@/language/lanFiles/en-US.json')
  }
  // return require('@/language/lanFiles/zh-CN.json') //本地调试 翻译是否正确
  // return require('@/language/lanFiles/enn-US.json') //本地调试 翻译是否正确
  // const jsonRes = await fetch(
  //   `https://translations${envStr}.apex.exchange/apexProWeb/${
  //     langTransfer[lang] || defaultLang
  //   }.json?stamp=${stamp}`,
  //   {
  //     method: 'GET',
  //     mode: 'cors',
  //     timeout: 1000,
  //   },
  // )
  // return await jsonRes.json()
}
export function fetchLangAndRender(lang) {
  const lastLang = getCurrentLang()
  const currentLang = lang || lastLang

  return new Promise((resolve) => {
    try {
      const langJSON = getLangMap(currentLang)
      resolve(langJSON)

      // 更新语言的本地存储标记
      setStoreItem('langStamp', String(Date.now()))
    } catch (error) {
      console.error('Failed to load language file:', error)
      resolve({})
    }
  })
}
// export function fetchLangAndRender(lang) {
//   const lastLang = getCurrentLang()
//   const currentLang = lang ? lang : lastLang
//   const lastStamp = getStoreItem('langStamp')
//   const newStamp = Date.now()
//   const stamp = lastStamp || newStamp
//   return new Promise((resolve) => {
//     getLangMap(currentLang, stamp)
//       .then((langJSON) => {
//         resolve(langJSON)
//         if (lastLang === currentLang && lastStamp) {
//           if (window?.requestIdleCallback) {
//             window?.requestIdleCallback(() => {
//               getLangMap(currentLang, newStamp).then()
//             })
//           } else {
//             getLangMap(currentLang, newStamp).then()
//           }
//         }
//         setStoreItem('langStamp', String(lastLang === currentLang ? newStamp : lastStamp))
//       })
//       .catch(() => {
//         resolve({})
//       })
//   })
// }
export function useLanguage() {
  const { t } = useTranslation()
  return t
}
