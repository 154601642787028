import { getQuery } from '@/components/trade/utils/funcs'
import { getStoreItem } from '@/utils/globalLocalStore'
export const langList = [
  {
    lang: 'en-US',
    name: 'English',
  },
  {
    lang: 'zh-CN',
    name: '简体中文',
  },
  {
    lang: 'zh-TW',
    name: '繁體中文',
  },
  // {
  //   lang: 'ko-KR',
  //   name: '한국어',
  // },
  // {
  //   lang: 'ja-JP',
  //   name: '日本語',
  // },
  // {
  //   lang: 'es-ES',
  //   name: 'Español',
  // },
  // {
  //   lang: 'tr-TR',
  //   name: 'Türkçe',
  // },
  // {
  //   lang: 'de-DE',
  //   name: 'Deutsch',
  // },
  // {
  //   lang: 'fr-FR',
  //   name: 'Français',
  // },
  // {
  //   lang: 'pt-PT',
  //   name: 'Protuguês',
  // },
  // {
  //   lang: 'fil-PH',
  //   name: 'Tagalog',
  // },
  // {
  //   lang: 'hi-IN',
  //   name: 'हिंदी',
  // },
  // {
  //   lang: 'th-TH',
  //   name: 'ไทย',
  // },
  // {
  //   lang: 'vi-VN',
  //   name: 'tiếng việt',
  // },
  // {
  //   lang: 'id-ID',
  //   name: 'bahasa Indonesia',
  // },
  // {
  //   lang: 'kk-KZ',
  //   name: 'қазақ тілі',
  // },
  // {
  //   lang: 'ru-RU',
  //   name: 'Русский',
  // },
  // {
  //   lang: 'uk-UA',
  //   name: 'українська',
  // },
]
export const langTransfer = {
  'en-US': 'en',
  'zh-TW': 'zh_Hant',
  'zh-CN': 'zh_Hans',
  'ko-KR': 'ko',
  'ja-JP': 'ja',
  'es-ES': 'es',
  'tr-TR': 'tr',
  'de-DE': 'de',
  'fr-FR': 'fr',
  'pt-PT': 'pt',
  'fil-PH': 'fil',
  'hi-IN': 'hi',
  'th-TH': 'th',
  'vi-VN': 'vi',
  'id-ID': 'id',
  'kk-KZ': 'kk',
  'ru-RU': 'ru',
  'uk-UA': 'uk',
}
export function getCurrentLang() {
  let urlLang = getQuery('lang', window.location.href)
  if (urlLang) {
    if (!langTransfer[urlLang]) {
      urlLang = getStoreItem('lang')
    }
  }
  const navigatorLang = window.navigator.language
  const lang = urlLang || getStoreItem('lang') || navigatorLang
  return langTransfer[lang] ? lang : 'en-US'
}
