let importAll = (requireContext) => requireContext.keys().forEach(requireContext)
try {
  importAll(require.context('./svg', true, /\.svg$/))
} catch (error) {}
export default function Icon(props) {
  const { name, color, width, height, ...restProps } = props
  const style = {}
  if (color) {
    style.fill = color
  }
  if (width || height) {
    if (width && height) {
      style.width = width
      style.height = height
    } else if (!width) {
      style.width = height
      style.height = height
    } else {
      style.width = width
      style.height = width
    }
    if (['px', 'PX'].indexOf(String(style.width)) === -1) {
      style.width = style.width + 'px'
    }
    if (['px', 'PX'].indexOf(String(style.height)) === -1) {
      style.height = style.height + 'px'
    }
  }
  return (
    <svg {...restProps} style={style}>
      <use xlinkHref={'#' + props.name} />
    </svg>
  )
}
