import { useCallback, useState } from 'react'
import Loading from '../loading'
import './style.scss'
export default function Button({
  txt,
  className,
  needLoading,
  children,
  onClick,
  disable = false,
  type,
  style,
  loadingEl,
}) {
  const [showLoading, setShowLoading] = useState(false)
  const closeLoading = useCallback(() => {
    setShowLoading(false)
  }, [])
  const handleClick = useCallback(() => {
    if (!showLoading) {
      if (needLoading) {
        setShowLoading(true)
      }
      onClick(closeLoading)
    }
  }, [showLoading, needLoading, onClick])
  return (
    <div
      className={`x-button ${className ? ` ${className}` : ''}${
        type ? ' com-btn-color-' + type : ''
      }${disable ? ' com-btn-disable' : ''}`}
      onClick={handleClick}
      style={{ ...style }}
    >
      <div className={`x-button-txt-ctr${showLoading ? ' x-button-txt-hide' : ''}`}>
        {children ? children : <span className="x-button-txt">{txt}</span>}
      </div>
      {showLoading &&
        (loadingEl ? (
          <div className="x-loading-txt">{loadingEl}</div>
        ) : (
          <Loading className="x-button-loading" />
        ))}
    </div>
  )
}
