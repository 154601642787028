export default [
  'AF', // Afghanistan
  'DZ', // Algeria
  'BD', // Bangladesh
  'BY', // Belarus
  'BR', // Brazil
  'CN', // China
  'CI', // Cote D'Ivoire (Ivory Coast)
  'CR', // Crimea
  'SV', // Sevastopol
  'CU', // Cuba
  'KP', // Democratic People's Republic of Korea (North Korea)
  'CD', // Democratic Republic of the Congo
  'EG', // Egypt
  'GN', // Guinea
  'ET', // Ethiopia
  'HT', // Haiti
  'IR', // Iran
  'IQ', // Iraq
  'LB', // Lebanon
  'LY', // Libya
  'ML', // Mali
  'MA', // Morocco
  'MM', // Myanmar (Burma)
  'NI', // Nicaragua
  'CG', // Republic of the Congo
  'GW', // Republic of Guinea-Bissau
  'RU', // Russia
  'SO', // Somalia
  'SS', // South Sudan
  'SD', // Sudan
  'SY', // Syria
  'TN', // Tunisia
  'US', // United States
  'VE', // Venezuela
  'YE', // Yemen
  'ZW', // Zimbabwe
]
