import fetchFN from 'dva/fetch'
import queryString from 'query-string'
import { getStoreItem } from '@/utils/globalLocalStore'
const fetch = fetchFN
const checkStatus = function (response, timer) {
  clearTimeout(timer)
  timer = null
  if (response.status >= 200 && response.status < 502) {
    return response
  } else {
    return Promise.reject(response.statusText)
  }
}
/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options) {
  if (!url) {
    return Promise.reject('no url params')
  }
  const method = options.method || 'POST'
  const fheaders = new Headers()
  // fheaders.append("X-Requested-With", "XMLHttpRequest");
  // fheaders.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
  fheaders.append('Accept-Language', getStoreItem('lang') ? getStoreItem('lang') : 'en-US')
  fheaders.append('Platform', 'WEB')
  if (options.headers) {
    for (const key in options.headers) {
      if ({}.hasOwnProperty.call(options.headers, key)) {
        fheaders.append(key, options.headers[key])
      }
    }
  }
  delete options.headers
  options = Object.assign(
    {
      credentials: options.credentials || 'include',
      method,
      mode: options.mode || 'cors',
      headers: fheaders,
    },
    options,
  )
  if (fheaders.get('Content-Type') === 'application/json') {
    options.body = JSON.stringify(options.body)
  } else if (options.body && Object.prototype.toString.call(options.body) === '[object Object]') {
    options.body = queryString.stringify(options.body)
  }
  if (options.data && !options.body) {
    options.body = options.data
  }
  if (options.body && Object.prototype.toString.call(options.body) === '[object Object]') {
    options.body = queryString.stringify(options.body)
  }
  if (/get/i.test(method)) {
    url += options.body ? (/\?/.test(url) ? '&' : '?') + options.body : ''
    delete options.body
  }
  // 添加请求超时
  let timeout = 20000 // 默认20秒超时
  let abort = null
  let timer = null
  const abortPromise = new Promise((resolve) => {
    abort = () => {
      return resolve({
        code: 408,
        dataCode: 408,
        msg: `request timeout: ${url}`,
      })
    }
  })
  const promise = Promise.race([
    abortPromise,
    fetch(`${url}`, options)
      .then(checkStatus)
      .then((res) => {
        clearTimeout(timer)
        timer = null
        // 服务端返回的body可能为空
        // http code [ 200 - 300) 返回body为具体数据内容
        // http code <200 || >=300 返回body为报错信息 { code: 415, message, title, ... }
        // 返回下载文件
        if (res.headers.get('Content-Type') === 'text/csv') {
          const filename = decodeURIComponent(
            res.headers.get('content-disposition').replace('attachment; filename=', ''),
          )
          return res.blob().then((blob) => {
            return {
              code: 0,
              filename,
              blob,
            }
          })
        } else {
          return res.json().then((d) => {
            if (res.status >= 200 && res.status < 300 && d) {
              let data = {}
              if (d) {
                data = {
                  code: 0,
                  ...d,
                }
              }
              return data
            } else {
              let data = {
                code: res.status,
                msg: res.statusText,
              }
              return data
            }
          })
        }
      })
      .catch((err) => {
        return Promise.reject(err)
      }),
  ])
  Object.defineProperty(promise, 'timeout', {
    set: (ts) => {
      if ((ts = +ts)) {
        timeout = ts
        timer = setTimeout(() => {
          abort()
        }, timeout)
      }
    },
    get: () => {
      return timeout
    },
  })
  promise.timeout = options.timeout || timeout
  return promise
}
