import instance from './instance'

/**
 * 获取授权信息
 * @param {object} params
 * @param {string} params.ticker
 * @param {string} params.chainId
 * @returns {Promise<object>} data
 * @property {string} data.from
 * @property {string} data.to
 * @property {string} data.contractAddress
 * @property {string} data.ticker
 */
export async function fetchSignContent(params) {
  return instance.post('/api/v1/order/authorize/sign-content', {}, { params })
}

/**
 * 授权签名校验
 * @param {object} data
 * @param {string} data.signature
 * @param {string} data.ticker
 * @param {string} data.chainId
 * @param {boolean} data.isRevoke
 * @param {string} data.amt
 * @param {string} data.type
 * @returns {Promise<string>} txHash
 */
export async function signAuthorize(data) {
  return instance.post('/api/v1/order/authorize', data)
}

/**
 * 创建订单
 * @param {object} data
 * @param {string} data.pairId
 * @param {string} data.orderType
 * @param {string} data.quantity
 * @param {string} data.price
 * @param {string} data.tradeType
 */
export async function createOrder(data) {
  return instance.post('/api/v1/order/create', data)
}

/**
 * 获取订单列表
 * @param {object} data
 * @param {number} data.pageSize = 100
 * @param {number} data.pageNum = 1
 * @returns {Promise<object>} data
 * @property {array} data.list
 * @property {number} data.totalCounts
 * @property {number} data.totalPages
 * @property {number} data.pageSize
 */
export async function fetchOrderList({ pageSize = 100, pageNum = 1 }) {
  return instance.post('/api/v1/order/list', { pageSize, pageNum })
}

/**
 * 获取订单详情
 * @param {object} params
 * @param {string} params.orderId
 * @returns {Promise<object>} data
 * @property {array} data.list
 * @property {number} data.totalCounts
 * @property {number} data.totalPages
 * @property {number} data.pageSize
 */
export async function fetchOrderDetail(params) {
  return instance.get('/api/v1/order/trading-detail', { params })
}

/**
 * 取消订单
 * @param {string} orderId
 * @returns {Promise<string>} status
 */
export async function cancelOrderById(orderId) {
  return instance.post(`/api/v1/order/cancel?orderId=${orderId}`)
}

/**
 * 取消所有订单
 * @returns {Promise<string>} status
 */
export async function cancelAllOrder() {
  return instance.post('/api/v1/order/cancel-all')
}
