import dva from 'dva'
import createLoading from 'dva-loading'
import { createBrowserHistory } from 'history'
import i18n from 'i18next'
import ReactDOM from 'react-dom'
import { initReactI18next } from 'react-i18next'
// models
import Layout from './models/layout'
// routes
import '@/assets/style/index.scss'
import '@/components/trade/services/ws.realtimePrivate'
import '@/components/trade/services/ws.realtimePublic'
import { getCurrentLang } from '@/config/lang'
import { fetchLangAndRender } from '@/language'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { ethers } from 'ethers'
import Updater from './components/deposit/components/Updater'
import IpLimit from './components/IpLimit/index'
import NuvoIframe from './components/basc-components/NuvoIframe/index'
import Routes from './route'
import { idleCompatible } from './utils/helper'
import restrictedCountries from './utils/restrictedCountries'

idleCompatible()
const lang = getCurrentLang()

const checkIPLocation = async () => {
  const isAllowed = sessionStorage.getItem('ip-allowed')
  if (isAllowed !== null) {
    return !!+isAllowed
  }

  // 本地开发环境不限制
  if (/localhost/.test(location.href)) {
    sessionStorage.setItem('ip-allowed', 1)
    return true
  }

  try {
    // 每月50000次后需付费
    const response = await fetch('https://ipinfo.io/json?token=6b7795b1d4a78e')
    const data = await response.json()
    const country = data.country
    console.log('Your country is:', country)
    const isAllowed = !restrictedCountries.includes(country)
    sessionStorage.setItem('ip-allowed', +isAllowed)
    return isAllowed
  } catch (error) {
    console.error('Error fetching IP location:', error)
    sessionStorage.setItem('ip-allowed', 1)
    return true
  }
}

const getLibrary = (provider) => {
  const library = new ethers.BrowserProvider(provider, 'any')
  library.pollingInterval = 3000 // frequency provider is polling
  return library
}

const start = async () => {
  // 先检查IP地址
  const isAllowed = await checkIPLocation()
  if (!isAllowed) {
    ReactDOM.render(<IpLimit />, document.querySelector('#root'))
    return
  }
  const data = {
    history: createBrowserHistory(),
    onError(e) {
      window.console.log(e)
    },
  }
  const app = dva(data)
  // 2. Plugins
  app.use(createLoading())
  // 3. Model
  app.model(Layout)
  // 4. Router
  const all_route = Routes
  app.router(all_route)
  // 5. Start
  const App = app.start()
  const Web3ProviderNetwork = createWeb3ReactRoot('NETWORK')
  fetchLangAndRender(lang).then((langJSON) => {
    const lanMap = {
      [lang]: {
        translation: langJSON,
      },
    }
    i18n.use(initReactI18next).init({
      resources: lanMap,
      fallbackLng: 'en-US',
      lng: lang,
    })
    ReactDOM.render(
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <NuvoIframe />
          <Updater />
          <App />
          {/* <IpLimit /> */}
        </Web3ProviderNetwork>
      </Web3ReactProvider>,
      document.querySelector('#root'),
    )
  })
}
start()
