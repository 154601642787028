import instance from './instance'

/**
 * 获取授权金额
 * @param {object} params
 * @param {string} params.from
 * @param {string} params.ticker
 * @param {string} params.to
 * @param {string} params.height
 * @returns {Promise<string>} amount
 */
export async function fetchAuthorizedAmount(params) {
  return instance.get(`/${params.from}/${params.ticker}/${params.to}/delegation?height=${params.height}`, {})
}

/**
 * 获取授权状态
 * @param {object} params
 * @param {string} params.hash
 * @returns {Promise<string>} status
 */
export async function fetchAuthorizedStatus(params) {
  return instance.get(`/${params.hash}/status`, {})
}