import instance from './instance'

/**
 * @typedef {Object} UserInfo
 * @property {string} loginEthAddress - 用户的以太坊地址
 * @property {string} avatar - 用户头像 URL
 * @property {string} displayName - 用户显示名称
 * @property {string} email - 用户邮箱
 * @property {string} mobile - 用户手机号
 * @property {number} registrationTime - 注册时间戳
 * @property {string} tradingVolume - 交易量
 * @property {string} userId - 用户 ID
 * @property {string} userType - 用户类型
 * @property {number} vipLevel - VIP 等级
 */

/**
 * 获取用户信息
 * @returns {Promise<UserInfo>}
 */
export async function fetchUserInfo() {
  return instance.get('/api/v1/user/info')
}

/**
 * @typedef {Object} UserFund
 * @property {string} accountId - 账户 ID
 * @property {string} amount - 资产余额
 * @property {string} amountIn - 充值金额
 * @property {string} amountOut - 提现金额
 * @property {string} chainId - 链 ID
 * @property {string} createTime - 创建时间
 * @property {string} frozen - 冻结金额
 * @property {string} frozenIn - 充值冻结金额
 * @property {string} frozenOut - 交易冻结金额
 * @property {string} id - 记录 ID
 * @property {string} tokenSymbol - 代币符号
 * @property {string} tokenType - 代币类型
 * @property {string} updateTime - 更新时间
 * @property {string} userId - 用户 ID
 * @property {string} walletId - 钱包 ID
 */

/**
 * 获取用户资产列表
 * @returns {Promise<Object>} data
 * @property {number} data.totalCounts
 * @property {number} data.totalPages
 * @property {number} data.pageSize
 * @property {array<UserFund>} data.list
 */
export async function fetchUserFunds() {
  return instance.get('/api/v1/user/funds')
}

/**
 * @typedef {Object} UserAsset
 * @property {string} loginEthAddress - 以太坊地址
 * @property {string} totalAsset - 总资产
 * @property {string} tradingVolume - 交易量
 * @property {string} userId - 用户 ID
 * @property {string} vipLevel - VIP 等级
 */

/**
 * 获取用户资产总览
 * @returns {Promise<UserAsset>}
 */
export async function fetchUserAssets() {
  return instance.get('/api/v1/user/asset')
}

/**
 * 获取用户资产图表数据
 * @param {Object} params - 参数
 * @param {string} params.interval - 时间间隔
 * @param {string} params.intervalType - 时间间隔类型
 * @returns {Promise<array<object>>} list
 * @property {number} list.time - 时间戳
 * @property {string} list.amount - 资产金额
 */
export async function fetchUserAssetsChart(params) {
  return instance.get('/api/v1/user/assets', { params })
}

/**
 * @typedef {Object} DepositRecord
 * @property {string} id - 记录 ID
 * @property {string} amount - 充值金额
 * @property {number} blockNumber - 区块高度
 * @property {number} chainId - 链 ID
 * @property {string} chainName - 链名称
 * @property {string} contractAddress - 合约地址
 * @property {string} createTime - 创建时间
 * @property {string} fromAddress - 转出地址
 * @property {string} gas - 交易所消耗的燃料量
 * @property {string} gasPrice - 燃料价格
 * @property {string} numFee - 交易手续费
 * @property {string} pstatus - 处理状态
 * @property {string} status - 交易状态
 * @property {string} tickerAmount - 代币数量
 * @property {string} tickerName - 代币名称
 * @property {string} toAddress - 转入地址
 * @property {string} tokenSymbol - 代币符号
 * @property {string} tokenType - 代币类型
 * @property {string} transferType -
 * @property {string} txHash - 交易哈希
 * 获取用户充值记录
 * @param {Object} params - 参数
 * @param {number} params.pageSize - 每页数量
 * @param {number} params.pageNum - 当前页码
 * @returns {Promise<Object>} data
 * @property {number} data.pageSize
 * @property {number} data.totalCounts
 * @property {number} data.totalPages
 * @property {array<DepositRecord>} data.list
 */
export async function fetchUserDepositList(params) {
  return instance.post('/api/v1/user/deposit/list', {}, { params })
}
