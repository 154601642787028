import XLocalStore from '@/components/trade/utils/funcs/localStore'
const localKey = 'l2dex.trade.stores'
if (!window.localStorage.tempClear2) {
  window.localStorage.removeItem(localKey)
  window.localStorage.removeItem('tempClear')
  window.localStorage.setItem('tempClear2', 'true')
}
const tradeStore = new XLocalStore(localKey)
export const setStoreItem = tradeStore.setStoreItem
export const getStoreItem = tradeStore.getStoreItem
export const rmStoreItem = tradeStore.rmStoreItem
