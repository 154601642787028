import Icon from '@/components/public/icon'
import Checkbox from '@/components/trade/components/checkbox'
import { useCallback, useState } from 'react'
import { useLanguage } from '@/language'
import Button from '@/components/trade/components/button'
import { setStoreItem } from '@/components/trade/utils/funcs/tradeStore'
export function DialogDom({ title, tips, storeKey, handleResult }) {
  const multiLanguage = useLanguage()
  const [hideNextCheck, setHideNextCheck] = useState(false)
  const handleOnCheck = useCallback((check) => {
    setStoreItem(storeKey, check)
    setHideNextCheck(check)
  }, [])
  const handleCancel = useCallback(() => {
    handleResult(false)
  }, [])
  const handleSure = useCallback(() => {
    handleResult(true)
  }, [])
  return (
    <div id="toastDialog" className="toast-async-dialog">
      <div className="toast-dialog-ctr">
        <div className="toast-dialog-title-ctr">
          <div className="toast-dialog-title">{title}</div>
          <Icon
            width={20}
            height={20}
            name="Close_thin"
            className="toast-dialog-close"
            onClick={handleCancel}
          />
        </div>
        <div className="toast-dialog-content">
          <div className="toast-dialog-tips">{tips}</div>
          {Boolean(storeKey) && (
            <Checkbox
              className="hide-next-time"
              label={multiLanguage('hideNextTime')}
              type="pure"
              checked={hideNextCheck}
              onChange={handleOnCheck}
            />
          )}

          <div className="toast-dialog-operate-line">
            <Button
              type="normal"
              txt={multiLanguage('btnCancel')}
              className="toast-dialog-operate-btn"
              onClick={handleCancel}
            />
            <Button
              type="operate"
              txt={multiLanguage('btnConfirm')}
              className="toast-dialog-operate-btn"
              onClick={handleSure}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
