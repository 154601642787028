import { useEffect } from 'react'
import './index.scss'
import axiosApi from '@/utils/axios'
import localforage from 'localforage'
import { localstorageKeys } from '@/interfaces/localstorageKeys'
import useGlobalLoading from '@/hooks/useGlobalLoading'
import { useUserStore } from '@/models/useUserStore'
import NudexLoading from '@/components/basc-components/NudexLoading'
import Toast from '@/components/trade/components/toast'
import { fetchUserInfo, fetchLoginToken } from '@/apis'

const NuvoIframe = () => {
  const setUserInfo = useUserStore((state) => state.setUserInfo)
  const setSecurityInfo = useUserStore((state) => state.setSecurityInfo)
  const setSecurityLevel = useUserStore((state) => state.setSecurityLevel)
  const initProvider = useUserStore((state) => state.initProvider)
  const initDexAssets = useUserStore((state) => state.initDexAssets)
  const initUserAssets = useUserStore((state) => state.initUserAssets)
  const initOrderList = useUserStore((state) => state.initOrderList)
  const initAllTokenList = useUserStore((state) => state.initAllTokenList)
  // const initInscriptionForm = useUserStore((state) => state.initInscriptionForm)
  const checkAuthorize = useUserStore((state) => state.checkAuthorize)
  const initOwnedTokens = useUserStore((state) => state.initOwnedTokens)
  const initPairList = useUserStore((state) => state.initPairList)
  const initFeeList = useUserStore((state) => state.initFeeList)
  const initDepositList = useUserStore((state) => state.initDepositList)
  const { globalLoading, setGlobalLoading } = useGlobalLoading()

  const initOauth2 = async () => {
    const oauth2 = window.document.querySelector('#Oauth2')
    const iframe = oauth2.querySelector('iframe')
    const url = new URL(process.env.REACT_APP_OAUTH2)
    url.searchParams.set('app_id', process.env.REACT_APP_DAPP_ID)
    url.searchParams.set('return_url', window.location.origin)
    iframe.src = url.href
    oauth2.addEventListener('click', () => {
      oauth2.style.display = 'none'
    })
  }
  const getUserInfo = async () => {
    const accessToken = await localforage.getItem(localstorageKeys.AccessToken_Nudex)
    if (accessToken) {
      const userInfo = await fetchUserInfo()

      if (userInfo) {
        setUserInfo(userInfo)
        await initProvider()
        initDexAssets()
        initUserAssets()
        initOrderList()
        initOwnedTokens(userInfo.loginEthAddress)
        checkAuthorize()
      } else {
        await localforage.removeItem(localstorageKeys.AccessToken_Nudex)
        await localforage.removeItem(localstorageKeys.AccessToken_Nuvo)
        window.location.reload()
      }
    } else {
      const href = window.location.href
      if (href.includes('/dashboard/')) {
        window.location.href = '/'
      }
    }
  }
  const getSecurityInfo = async () => {
    const res = await axiosApi.nuvoApi.get('/api/v1/oauth2/settings/security_info')
    if (res?.code === 200) {
      setSecurityInfo(res.data)
      let count = 0
      const info = res.data
      if (info.gg_open || info.sms_open) {
        count += 1
      }
      if (info.anti_code_open) {
        count += 1
      }
      if (info.wallet_pwd_open) {
        count += 1
      }
      if (info.email) {
        count += 1
      }
      if (info.new_ip_verify) {
        count += 1
      }

      if (count <= 2) {
        setSecurityLevel('Low')
      }
      if (count > 2 && count < 5) {
        setSecurityLevel('Medium')
      }
      if (count === 5) {
        setSecurityLevel('High')
      }
    }
  }

  const login = async (code) => {
    setGlobalLoading(true)
    const { nuvoToken, token } = await fetchLoginToken({ code }).catch((error) => {
      Toast.error(error?.message)
    })

    if (nuvoToken && token) {
      await localforage.setItem(localstorageKeys.AccessToken_Nuvo, nuvoToken)
      await localforage.setItem(localstorageKeys.AccessToken_Nudex, `Bearer ${token}`)
      getUserInfo()
      getSecurityInfo()
    }

    setGlobalLoading(false)
  }

  const initLogin = () => {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    if (code) {
      login(code)
      url.searchParams.delete('code')
      window.history.replaceState({}, '', url.toString())
    }
  }

  useEffect(() => {
    initOauth2()
    getUserInfo()
    getSecurityInfo()
    initFeeList()
    initDepositList()
    initAllTokenList().then(() => {
      initPairList()
    })
    initLogin()
    window.addEventListener('message', async (event) => {
      const oauth2 = window.document.querySelector('#Oauth2')
      const iframe = oauth2.querySelector('iframe')
      if (event.origin === new URL(iframe.src).origin) {
        if (event.data === 'close') {
          oauth2.style.display = 'none'
        } else if (event.data?.type === 'login-success') {
          login(event.data.code)
        }
      }
    })
  }, [])

  return (
    <div id="Oauth2">
      <iframe id="Oauth2-iframe"></iframe>
      <NudexLoading loading={globalLoading} />
    </div>
  )
}

export default NuvoIframe
