import Axios from 'axios'
import { localstorageKeys } from '@/interfaces/localstorageKeys'
import localforage from 'localforage'

const initResponse = (response) => {
  const status = response?.data?.statusCode || response?.status || 404
  if (status >= 200 && status < 300) {
    return response.data
  } else {
    console.error(`Unknown Error:`, response?.data)
    return null
  }
}

// Nuvo api
const nuvoApi = Axios.create({
  baseURL: process.env.REACT_APP_API_NUVO,
})
nuvoApi.interceptors.request.use(
  async function (config) {
    const nuvoToken = (await localforage.getItem(localstorageKeys.AccessToken_Nuvo)) || {}
    const accessToken = nuvoToken.accessToken
    if (accessToken) {
      config.headers.Authorization = accessToken
      config.headers['access-token'] = accessToken
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)
nuvoApi.interceptors.response.use(
  function (response) {
    return initResponse(response)
  },
  function (error) {
    return initResponse(error.response)
  },
)

const axiosApi = {
  nuvoApi,
}
export default axiosApi
