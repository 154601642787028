import request from '../utils/request'
import urls from '../config/api'
/**
 * 通用请求
 * @param {string} key 请求地址，参考urls中的key
 * @param {object} params 请求参数
 * @return {promise}
 */
export default function getData(key) {
  return ({ payload, ...props }) => {
    let url = urls[key]
    return request(url, { body: payload, ...props })
  }
}
