import { useState } from 'react'
import './style.scss'
export default function Checkbox({ type = 'pure', label, disable, checked, onChange, className }) {
  const handleChange = () => {
    onChange(!checked)
  }
  const [checkboxId] = useState(Math.random().toString(36).slice(2))
  return (
    <div className={`x-checkbox-ctr checkbox-${type}${className ? ` ${className}` : ''}`}>
      <input
        type="checkbox"
        id={checkboxId}
        className="x-checkbox-input"
        checked={checked}
        onChange={handleChange}
        disabled={disable}
      />
      <label
        className={
          checked
            ? `x-checkbox-label is-checked${disable ? ' x-disable' : ''}`
            : `x-checkbox-label${disable ? ' x-disable' : ''}`
        }
        htmlFor={checkboxId}
      >
        <div className="x-forge-box" />
        <span className="x-checkbox-label-txt">{label}</span>
      </label>
    </div>
  )
}
