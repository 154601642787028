import Icon from '@/components/public/icon'
import { cloneElement, useState } from 'react'
import './style.scss'
const IconMap = {
  error: 'Toast_fail',
  info: 'Toast_info',
  loading: 'Toast_loading',
  success: 'Toast_success',
  staticLoading: 'Toast_static_loading',
}
export function ToastDom({ msg, btn, handleClose }) {
  const { type, title, txt } =
    typeof msg === 'string' || msg === undefined
      ? { type: 'info', title: 'Information', txt: msg || 'Network exception' }
      : msg
  const icon = IconMap[type]
  const [lightMode, setLightMode] = useState(false)
  const [activeTitle, setActiveTitle] = useState(title)
  const toggleLight = () => {
    setLightMode(true)
  }
  const toggleDefault = () => {
    setLightMode(false)
  }
  const [curIcon, setIcon] = useState(icon)
  const toggleIcon = (t) => {
    setIcon(IconMap[t])
  }
  return (
    <div
      className="x-toast-ctr toast-ctr-show"
      style={lightMode ? { margin: '0 0 0 auto', width: 'max-content', padding: '13.5px' } : {}}
    >
      {!lightMode && (
        <Icon name="close_small" className="x-toast-icon-close" onClick={handleClose} />
      )}

      <div className="x-toast-info-ctr" style={lightMode ? { alignItems: 'center' } : {}}>
        <Icon name={curIcon} className={`x-toast-icon ${curIcon}-icon`} />

        <div className="x-toast-msg">
          {!lightMode && <div className="x-toast-title">{activeTitle}</div>}
          <div className="x-toast-txt" style={lightMode ? { marginTop: 0 } : {}}>
            {typeof txt === 'string'
              ? txt
              : txt &&
                cloneElement(txt, {
                  handleClose,
                  lightMode,
                  toggleIcon,
                  toggleLight,
                  toggleDefault,
                  setActiveTitle,
                })}
          </div>
        </div>
      </div>
      {btn && (
        <div className="x-toast-operate-ctr">
          <div className="x-toast-btn-ctr">{btn}</div>
        </div>
      )}
    </div>
  )
}
