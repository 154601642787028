import dynamic from 'dva/dynamic'
import { Route, Router, Switch } from 'dva/router'
import route_map from './config/route_map'
function RouterConfig({ history, app }) {
  const NotFoundRC = dynamic({
    app,
    component: () => import('./pages/404'),
  })
  const LandingPageRc = dynamic({
    app,
    models: () => [],
    component: () => import('./pages/landingpage'),
  })

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={route_map.landingpage} component={LandingPageRc} />

        <Route component={NotFoundRC} />
      </Switch>
    </Router>
  )
}
export default RouterConfig
