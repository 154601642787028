export default {
  delay: async (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout)
    })
  },
  /**
   * 字符串截短
   * @param str 原字符串
   * @param n 保留位数
   */
  shortStr: (str, n) => {
    if (!str || Object.prototype.toString.call(str) != '[object String]') {
      return ''
    }
    if (Number.isNaN(Number(n)) || Number(n) === 0) {
      return str
    }
    if (str.length <= n) {
      return str
    }
    const len = Math.ceil(n / 2)
    let pre = str.slice(0, len)
    let next = str.slice(str.length - len, str.length)
    return pre + '...' + next
  },
  /**
   * bex颜色转为rgba
   * @param String #ff00ff #f0f
   * @param Number a 0-1
   * @return String rgba(r,g,b,a)
   */
  hex_to_rgba: (hex, a = 1) => {
    if (!hex || hex.indexOf('#') == -1) {
      return 'rgba(0,0,0,0)'
    }
    if (hex.length != 7 && hex.length != 4) {
      console.error(`${hex} is not hex color`)
      return 'rgba(0,0,0,0)'
    }
    let s = hex.replace('#', '').match(/^(..?)(..?)(..?)/) || []
    return `rgba(${parseInt('0x' + s[1] + (s[1].length == 1 ? s[1] : ''))},${parseInt(
      '0x' + s[2] + (s[2].length == 1 ? s[2] : ''),
    )},${parseInt('0x' + s[3] + (s[3].length == 1 ? s[3] : ''))},${Number(a)})`
  },
  /**
   * Quote 币种兑换汇率查询 1inch api
   * @param string fromTokenAddress
   * @param string toTokenAddress
   * @param number  amount 需要按照币种精度处理
   * @param chainId number
   * @return obj
   */
  quote: (obj, chainId) => {
    if (!chainId) {
      throw new Error('param chainId required!')
    }
    const _p = { ...obj, chainId: chainId.toString() }
    // const url =
    //   'https://api.1inch.io/v4.0/' + chainId + '/quote' + '?' + (obj ? new URLSearchParams(_p).toString() : '');
    const url = '/api/v1/other/inch-quote' + '?' + (obj ? new URLSearchParams(_p).toString() : '')
    return fetch(url)
  },
  swap: (obj, chainId) => {
    if (!chainId) {
      throw new Error('param chainId required!')
    }
    const _p = { ...obj, chainId: chainId.toString() }
    // const url =
    //   'https://api.1inch.io/v4.0/' + chainId + '/swap' + '?' + (obj ? new URLSearchParams(obj).toString() : '');
    const url = '/api/v1/other/inch-swap' + '?' + (obj ? new URLSearchParams(_p).toString() : '')
    return fetch(url)
  },
  genTotalRandomGuid: () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  },
  // https://www.lixueduan.com/posts/web/%E8%AE%BE%E5%A4%87%E8%BF%BD%E8%B8%AA%E4%B9%8Bcanvas%E6%8C%87%E7%BA%B9/
  genMd5Guid: (starkKey) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const txt = starkKey
    ctx.textBaseline = 'top'
    ctx.font = "14px 'Arial'"
    ctx.fillStyle = '#f60'
    ctx.fillRect(125, 1, 62, 20)
    ctx.fillStyle = '#069'
    ctx.fillText(txt, 2, 15)
    ctx.fillStyle = 'rgba(102, 204, 0, 0.7)'
    ctx.fillText(txt, 4, 17)
    const b64 = canvas.toDataURL()
    return extractCRC32FromBase64(b64)
  },
}
function string2Hex(str) {
  let result = ''
  for (let i = 0; i < str.length; i++) {
    let askii = str.charCodeAt(i)
    if (askii < 0x0f) {
      // 小于16转为16进制后在前面补零
      result += '0'
    }
    result += askii.toString(16)
  }
  return result
}
function extractCRC32FromBase64(base64) {
  base64 = base64.replace('data:image/png;base64,', '')
  const bin = Buffer.from(base64, 'base64')
  // PNG图片第29到第33位是PNG元数据的CRC32校验码 这里只和图片尺寸有关
  // PNG图片倒数第16到第12位这四位就是该图片的CRC32校验码
  const crcAskii = bin.slice(-16, -12)
  return string2Hex(crcAskii.toString())
}
export let GaEvent
;(function (GaEvent) {
  // v1
  GaEvent['display_trade'] = 'display_trade'
  GaEvent['display_ack_dialog'] = 'display_ack_dialog'
  GaEvent['click_btn_ack_agree'] = 'click_btn_ack_agree'
  GaEvent['click_btn_ack_disAgree'] = 'click_btn_ack_disAgree'
  GaEvent['click_btn_ack_close'] = 'click_btn_ack_close'
  GaEvent['display_choose_network_wallet'] = 'display_choose_network_wallet'
  GaEvent['display_sign_dialog'] = 'display_sign_dialog'
  GaEvent['display_userinfo_setting'] = 'display_userinfo_setting'
  GaEvent['display_dialog_deposit'] = 'display_dialog_deposit'
  GaEvent['display_dialog_withdraw'] = 'display_dialog_withdraw'
  GaEvent['click_btn_connect_wallet'] = 'click_btn_connect_wallet'
  GaEvent['click_btn_choose_network_wallet'] = 'click_btn_choose_network_wallet'
  GaEvent['click_btn_register_create_starkKey'] = 'click_btn_register_create_starkKey'
  GaEvent['click_btn_register_onboarding'] = 'click_btn_register_onboarding'
  GaEvent['click_btn_register_close'] = 'click_btn_register_close'
  GaEvent['click_btn_userinfo_setting_confirm'] = 'click_btn_userinfo_setting_confirm'
  GaEvent['click_btn_userinfo_close'] = 'click_btn_userinfo_close'
  GaEvent['click_btn_approve_token'] = 'click_btn_approve_token'
  GaEvent['click_btn_deposit'] = 'click_btn_deposit'
  GaEvent['click_btn_show_deposit'] = 'click_btn_show_deposit'
  GaEvent['click_btn_show_withdraw'] = 'click_btn_show_withdraw'
  GaEvent['click_btn_withdraw'] = 'click_btn_withdraw'
  // USDT需求时增加
  // https://uponly.larksuite.com/sheets/Ek8nsPHg4hSm8WtICoKuLT5dsyh?sheet=8d2fa7
  // pageView
  GaEvent['pv_trade'] = 'pv_trade'
  GaEvent['pv_leverage'] = 'pv_leverage'
  GaEvent['pv_dashboard_overview'] = 'pv_dashboard_overview'
  GaEvent['pv_dashboard_profile'] = 'pv_dashboard_profile'
  GaEvent['pv_dashboard_orders'] = 'pv_dashboard_orders'
  GaEvent['pv_dashboard_transfers'] = 'pv_dashboard_transfers'
  GaEvent['pv_dashboard_funding'] = 'pv_dashboard_funding'
  // click
  GaEvent['click_tab_net'] = 'click_tab_net'
  GaEvent['click_tab_net_select'] = 'click_tab_net_select'
  GaEvent['click_tab_trade'] = 'click_tab_trade'
  GaEvent['click_tab_dashboard'] = 'click_tab_dashboard'
  GaEvent['click_tab_leaderboard'] = 'click_tab_leaderboard'
  GaEvent['click_tab_reward'] = 'click_tab_reward'
  GaEvent['click_tab_referral'] = 'click_tab_referral'
  GaEvent['click_tab_earn'] = 'click_tab_earn'
  GaEvent['click_tab_earn_select'] = 'click_tab_earn_select'
  GaEvent['click_trade_market'] = 'click_trade_market'
  GaEvent['click_trade_market_select'] = 'click_trade_market_select'
  GaEvent['click_trade_price'] = 'click_trade_price'
  GaEvent['click_trade_depth'] = 'click_trade_depth'
  GaEvent['click_trade_fundingrate'] = 'click_trade_fundingrate'
  GaEvent['click_trade_detail'] = 'click_trade_detail'
  GaEvent['click_trade_risklimit'] = 'click_trade_risklimit'
  GaEvent['click_trade_buy'] = 'click_trade_buy'
  GaEvent['click_trade_sell'] = 'click_trade_sell'
  GaEvent['click_trade_leverage'] = 'click_trade_leverage'
  GaEvent['click_trade_leverage_confirm'] = 'click_trade_leverage_confirm'
  GaEvent['click_dashboard_profile_time_total'] = 'click_dashboard_profile_time_total'
  GaEvent['click_dashboard_profile_time_trade'] = 'click_dashboard_profile_time_trade'
  GaEvent['click_dashboard_profile_time_day'] = 'click_dashboard_profile_time_day'
  GaEvent['click_dashboard_profile_time_market'] = 'click_dashboard_profile_time_market'
  GaEvent['click_dashboard_profile_tradenow'] = 'click_dashboard_profile_tradenow'
  GaEvent['click_top_questionmark'] = 'click_top_questionmark'
  GaEvent['click_top_notification'] = 'click_top_notification'
  GaEvent['click_top_scan'] = 'click_top_scan'
  GaEvent['click_dashboard_order_market'] = 'click_dashboard_order_market'
  GaEvent['click_dashboard_order_date'] = 'click_dashboard_order_date'
  GaEvent['click_dashboard_order_search'] = 'click_dashboard_order_search'
  GaEvent['click_dashboard_order_export'] = 'click_dashboard_order_export'
  GaEvent['click_dashboard_transfers_action'] = 'click_dashboard_transfers_action'
  GaEvent['click_dashboard_transfers_chain'] = 'click_dashboard_transfers_chain'
  GaEvent['click_dashboard_transfers_date'] = 'click_dashboard_transfers_date'
  GaEvent['click_dashboard_transfers_search'] = 'click_dashboard_transfers_search'
  GaEvent['click_dashboard_funding_market'] = 'click_dashboard_funding_market'
  GaEvent['click_dashboard_funding_date'] = 'click_dashboard_funding_date'
  GaEvent['click_dashboard_funding_search'] = 'click_dashboard_funding_search'
  GaEvent['click_dashboard_community'] = 'click_dashboard_community'
  // v2
  // deposit&withdraw
  GaEvent['click_trade_deposit'] = 'click_trade_deposit'
  GaEvent['click_trade_withdraw'] = 'click_trade_withdraw'
  GaEvent['click_dashboard_profile_deposit'] = 'click_dashboard_profile_deposit'
  GaEvent['click_dashboard_deposit'] = 'click_dashboard_deposit'
  GaEvent['click_dashboard_withdraw'] = 'click_dashboard_withdraw'
  // slp
  GaEvent['click_slp_learnmore'] = 'click_slp_learnmore'
  GaEvent['click_slp_subscribe'] = 'click_slp_subscribe'
  GaEvent['click_slp_history'] = 'click_slp_history'
  GaEvent['click_slp_redeem'] = 'click_slp_redeem'
  GaEvent['click_slp_subscribe_confirm'] = 'click_slp_subscribe_confirm'
  GaEvent['click_slp_redeem_confirm'] = 'click_slp_redeem_confirm'
})(GaEvent || (GaEvent = {}))
export function eventTracking(event, config = {}) {
  window?.gtag?.('event', event, {
    ...config,
    address: config?.address ? config?.address.replace('0x', '') : '',
    timestamp: +new Date(),
    referer: document?.referrer,
  })
}
export const verifyDevice = () => {
  return /android|iphone/i.test(window.navigator.userAgent) ? 'mobile' : 'pc'
}
export const idleCompatible = () => {
  window.requestIdleCallback =
    window.requestIdleCallback ||
    function (cb) {
      const start = Date.now()
      return setTimeout(function () {
        cb({
          didTimeout: false,
          timeRemaining: function () {
            return Math.max(0, 50 - (Date.now() - start))
          },
        })
      }, 1)
    }
  window.cancelIdleCallback =
    window.cancelIdleCallback ||
    function (id) {
      clearTimeout(id)
    }
}
