import instance from './instance'

/**
 * 获取用户 token
 * @param {Object} params
 * @param {string} params.code - 登录 code
 * @returns {Promise<Object>} data
 * @property {string} data.nuvoToken
 * @property {string} data.token
 */
export async function fetchLoginToken(params) {
  return instance.get('/api/v1/login/user/token', { params })
}

/**
 * 获取市场概况
 * @returns {Promise<Object>} data
 * @property {string} data.24hVolumn - 24 小时成交量
 * @property {string} data.totalTokens - 总代币数
 * @property {string} data.totalVolume - 总交易量
 */
export async function fetchMarketSummary() {
  return instance.get('/api/v1/market/summary')
}

/**
 * 获取市场列表
 * @param {Object} params
 * @param {number} params.pageSize - 每页数量
 * @param {number} params.pageNum - 当前页码
 * @returns {Promise<Object>} data
 * @property {Array} data.list - 市场列表
 */
export async function fetchPairList(params) {
  return instance.get('/api/v1/token-pair/pair-list', { params })
}

/**
 * 获取手续费列表
 * @returns {Promise<Object>} data
 * @property {Array} data - 手续费列表
 * @property {string} data.level - 手续费等级
 * @property {string} data.levelName - 手续费等级名称
 * @property {string} data.makerRatio - maker 手续费
 * @property {string} data.takerRatio - taker 手续费
 * @property {string} data.maxVolume - 最大交易量
 * @property {string} data.minVolume - 最小交易量
 */
export async function fetchFeeList() {
  return instance.get('/api/v1/pair-list/fee')
}

/**
 * @typedef {Object} KlineData
 * @property {timestamp} time - 时间戳
 * @property {string} open - 开盘价
 * @property {string} high - 最高价
 * @property {string} low - 最低价
 * @property {string} close - 收盘价
 * @property {string} volume - 成交量
 * @property {string} interval - 时间间隔
 * @property {string} turnover - 交易量
 * @property {string} start - 开始时间
 * @property {string} end - 结束时间
 * 获取 K 线数据
 * @param {Object} params
 * @param {string} params.pairId - 交易对 ID
 * @param {string} params.resolution - 时间分辨率
 * @param {number} params.start - 开始时间
 * @param {number} params.end - 结束时间
 * @returns {Promise<Object>} data
 * @property {Array<KlineData>} data[symbol] - K 线数据
 */
export async function fetchKlineList(params) {
  return instance.get('/api/v1/klines', { params })
}
