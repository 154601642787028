function getIsMobile() {
  return 'ontouchstart' in document.documentElement
}
export const ENV = 'dev'
export const isProd = ENV === 'prod'
export const isGray = !!window.location.hostname.match(/^gray.pro\.|^gray-pro\./i)
export const isDev = ENV === 'dev'
export const isQa = ENV === 'qa'
export const isTestnet = ENV === 'testnet'
export const isLocal = ENV === 'localhost'
export const isMobile = getIsMobile()
export const depositVersion = '0.0.1'
