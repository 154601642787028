import { PolisClient } from '@metis.io/middleware-client'
import { create } from 'zustand'
import dayjs from 'dayjs'
import { ethers } from 'ethers'
import { nuvo } from '@/utils/nuvo'
// import formatPrecisionUI from '@/utils/formatPrecisionUI'
import formatPriceUI from '@/utils/pairPriceDiget'
import localforage from 'localforage'
import { localstorageKeys } from '@/interfaces/localstorageKeys'
import {
  fetchOrderList,
  fetchUserFunds,
  fetchUserAssets,
  fetchDepositTokenList,
  fetchPairList,
  fetchFeeList,
  fetchSignContent,
  fetchOwnedTokens,
  fetchListingTokens
} from '@/apis'
import {
  fetchAuthorizedAmount
} from '@/apis/indexserApi'


const chainId = process.env.REACT_APP_CHAIN_ID

const formatTokenList = (tokens) => {
  const list = tokens.map((token) => {
    let data = {
      p: 'nip-20',
      op: 'mint',
      tick: '-',
      id: 0,
      amt: 0,
      dataurl: '',
      of: '',
      contract: undefined,
      cmint: undefined,
    }
    if (token.data.startsWith('data:,')) {
      try {
        data = JSON.parse(token.data.slice(6))
      } catch (error) {
        console.error(error)
      }
    }
    const ticker = {
      name: token.ticker,
      type: data.p,
      blockNumber: token.block_number,
      maxAmount: Number(token.maxamount),
      maxMint: Number(token.mint_limit),
      dataurl: data.dataurl || '',
      officialMint: data.of || '',
      contract: data.contract,
      progress: 0,
      holders: '',
      cmint: data.cmint || undefined,
      ownedTokens: [],
    }
    return ticker
  })
  return list
}

export const parseToken = (tokenOriginal) => {
  const result = {
    symbol: '',
    symbolOriginal: '',
    decimals: 0,
    original: tokenOriginal,
  }
  if (tokenOriginal.startsWith('NUDEX_')) {
    const array = tokenOriginal.split('_')
    result.symbol = array[1]
    result.decimals = Number(array[2])
  } else {
    result.symbol = tokenOriginal
  }
  result.symbolOriginal = result.symbol
  if (result.symbol.length > 6) {
    result.symbol = result.symbol.slice(0, 6) + '...'
  }
  return result
}

export const formatToken = (tokenOriginal) => {
  if (tokenOriginal?.startsWith('NUDEX_')) {
    const array = tokenOriginal.split('_')
    return array[1]
  } else {
    return tokenOriginal
  }
}

export const getPair = (pairId) => {
  const pair = {
    tokenA: '',
    tokenADecimals: 0,
    tokenASymbol: '',
    tokenAOriginal: '',
    tokenB: '',
    tokenBDecimals: 0,
    tokenBSymbol: '',
    tokenBOriginal: '',
    pair: '',
    pairOriginal: '',
  }
  if (pairId && pairId.includes('/')) {
    const [tokenAOriginal, tokenBOriginal] = pairId.split('/')
    const tokenA = parseToken(tokenAOriginal)
    pair.tokenA = tokenA.symbol
    pair.tokenASymbol = tokenA.symbolOriginal
    pair.tokenADecimals = tokenA.decimals
    pair.tokenAOriginal = tokenA.original

    const tokenB = parseToken(tokenBOriginal)
    pair.tokenB = tokenB.symbol
    pair.tokenBSymbol = tokenB.symbolOriginal
    pair.tokenBDecimals = tokenB.decimals
    pair.tokenBOriginal = tokenB.original

    pair.pair = `${pair.tokenA}/${pair.tokenB}`
    pair.pairInPath = `${pair.tokenASymbol}${pair.tokenBSymbol}`
    pair.pairOriginal = `${pair.tokenASymbol}/${pair.tokenBSymbol}`
  }
  return pair
}
export const parsePairLogo = (pairId) => {
  if (pairId && pairId.includes('/')) {
    const [tokenAOriginal] = pairId.split('/')
    return formatToken(tokenAOriginal)
  } else {
    return pairId
  }
}

const formatPairList = (list) => {
  return list.map((e) => {
    const pair = getPair(e.pairId)
    return {
      ...pair,
      pairId: e.pairId,
      tags: e.tags || [],
      price: null,
      usdPrice: null,
      percent: null,
      isFavorite: false,
      digitMerge: e.digitMerge,
      priceDig: e.priceDig,
      minTradeBaseToken: nuvo.formatUnits(e.minTradeBaseToken, pair.tokenADecimals),
      maxTradeBaseToken: nuvo.formatUnits(e.maxTradeBaseToken, pair.tokenADecimals),
      minTradeQuoteToken: nuvo.formatUnits(e.maxTradeBaseToken, pair.tokenBDecimals),
      maxTradeQuoteToken: nuvo.formatUnits(e.maxTradeQuoteToken, pair.tokenBDecimals),
    }
  })
}

const getProvider = async (accessToken) => {
  const polisClient = new PolisClient({
    chainId,
    appId: process.env.REACT_APP_DAPP_ID,
    apiHost: process.env.REACT_APP_API_NUVO,
    oauthHost: process.env.REACT_APP_OAUTH2,
  })
  await polisClient.connect(accessToken, true)
  return polisClient.web3Provider
}

export const useUserStore = create((set, get) => ({
  provider: null,
  initProvider: async () => {
    let provider = get().provider
    if (!provider) {
      const nuvoToken = await localforage.getItem(localstorageKeys.AccessToken_Nuvo)
      provider = await getProvider(nuvoToken.accessToken)
      set({ 
        provider: provider,
        blockNumber: await provider.getBlockNumber() 
      })
    }
  },
  blockNumber: 0,
  userInfo: null,
  setUserInfo: (payload) => set({ userInfo: { ...payload } }),
  securityLevel: 'Low',
  setSecurityLevel: (payload) => set({ securityLevel: payload }),
  securityInfo: null,
  setSecurityInfo: (payload) => set({ securityInfo: { ...payload } }),
  allTokenList: [],
  initAllTokenList: async () => {
    const tokens = await fetchListingTokens()
    if (tokens.length) {
      const list = formatTokenList(tokens)
      set({ allTokenList: list })
    }
  },
  dexAssets: [],
  setDexAssets: (payload) => set({ dexAssets: { ...payload } }),
  initDexAssets: async () => {
    const { list } = await fetchUserFunds()
    if (list) {
      const format = (e) => {
        const token = parseToken(e.tokenSymbol)
        const amount = nuvo.formatUnits(e.amount, token.decimals)
        const frozenIn = nuvo.formatUnits(e.frozenIn, token.decimals)
        const frozenOut = nuvo.formatUnits(e.frozenOut, token.decimals)
        const amountCanUse = nuvo.sub(amount, frozenOut)
        return {
          tokenOriginal: token.original,
          tokenDecimals: token.decimals,
          tokenSymbol: token.symbol,
          tokenSymbolOriginal: token.symbolOriginal,
          tokenType: e.tokenType,
          amount, // 总资产
          amountCanUse, // amount - frozenOut 可用资产
          frozenIn, // 充值冻结中
          frozenOut, // 交易冻结中
        }
      }
      set({ dexAssets: list.map(format) })
    }
  },
  orderList: [],
  initOrderList: async () => {
    const { list } = await fetchOrderList({
      pageSize: 100,
    })

    if (list) {
      list.sort((a, b) => a.createTime - b.createTime)
      const array = []
      for (const e of list) {
        const pair = getPair(e.pairId)
        const price = ethers.formatUnits(e.price, pair.tokenBDecimals)
        const filledQuantity = ethers.formatUnits(e.filledQuantity, pair.tokenADecimals)
        const quantity = ethers.formatUnits(e.quantity, pair.tokenADecimals)
        array.unshift({
          orderId: e.id,
          pairId: e.pairId,
          Markets: pair.pair,
          orderType: e.orderType?.toLowerCase(),
          tradeType: e.tradeType?.toLowerCase(),
          Price: formatPriceUI(price, e.pairId, 0) + ' ' + pair.tokenB,
          FilledTotal: filledQuantity + '/' + quantity,
          FilledOrder:
            nuvo.usd(nuvo.mul(filledQuantity, price)) + '/' + nuvo.usd(nuvo.mul(quantity, price)),
          createTime: e.createTime,
          OrderTime: e.createTime ? dayjs(e.createTime).format('YYYY-MM-DD HH:mm:ss') : '-',
          Status: e.orderStatus.charAt(0) + e.orderStatus.toLowerCase().slice(1),
        })
      }
      set({ orderList: array })
    }
  },
  pairId: 'NUDEX_MASON_0/NUDEX_USDT_18',
  pairObj: {},
  setPairId: (payload) => set({ pairId: payload }),
  setPairObj: (payload) => set({ pairObj: payload }),
  pairPrice: '0',
  setPairPrice: (payload) => set({ pairPrice: payload }),
  pairList: [],
  getPairTags: (pairId) => {
    const pairList = get().pairList
    for (const e of pairList) {
      if (e.pairId === pairId) {
        return e.tags
      }
    }
    return []
  },
  officialPartner: (pairId) => {
    const tags = get().getPairTags(pairId)
    if (tags.includes('partner')) {
      return 'partner'
    } else if (tags.includes('official')) {
      return 'official'
    }
    return ''
  },
  setPairList: (payload) => set({ pairList: [...payload] }),
  initPairList: async () => {
    const { list } = await fetchPairList({
      pageSize: 100,
    }).catch(() => {})

    if (list) {
      set({ pairList: formatPairList(list) })
    }
  },
  getPairDig: (pairId) => {
    const pairList = get().pairList
    for (const e of pairList) {
      if (e.pairId === pairId) {
        return e.priceDig
      }
    }
    return 4 //todo:默认精度暂时为4
  },
  depositList: [],
  initDepositList: async () => {
    const list = await fetchDepositTokenList().catch(() => {
      return []
    })

    if (list?.length) {
      set({ depositList: list })
    }
  },
  ownedTokensList: [],
  initOwnedTokens: async (address) => {
    const tokens = await fetchOwnedTokens(address)
    set({ ownedTokensList: tokens })
  },
  setOwnedTokensList: (payload) => set({ ownedTokensList: payload || [] }),
  feeList: [],
  initFeeList: async () => {
    const data = await fetchFeeList().catch(() => {})
    if (data) {
      set({ feeList: data })
    }
  },
  userAssets: {},
  initUserAssets: async () => {
    const res = await fetchUserAssets()
    if (res) {
      set({ userAssets: res })
    }
  },
  authorizeTokenA: '0',
  setAuthorizeTokenA: (payload) => set({ authorizeTokenA: payload }),
  authorizeTokenB: '0',
  setAuthorizeTokenB: (payload) => set({ authorizeTokenB: payload }),
  checkAuthorize: async (pairId = '', refresh = '') => {
    if (pairId === get().pairId && !refresh) {
      return
    }
    if (!pairId) {
      pairId = get().pairId
    }
    const { tokenAOriginal, tokenBOriginal } = getPair(pairId)
    const signContent = await fetchSignContent({
      ticker:tokenBOriginal,
      chainId,
    })
    fetchAuthorizedAmount({ 
      from: signContent.from,
      ticker: tokenAOriginal,
      to: signContent.to,
      height: get().blockNumber
    }).then((res) => {
      set({ authorizeTokenA: res?.delegation })
    })

    fetchAuthorizedAmount({
      from: signContent.from,
      ticker: tokenBOriginal,
      to: signContent.to,
      height: get().blockNumber
    }).then((res) => {
      set({ authorizeTokenB: res?.delegation })
    })
  },

  outerPrice: 0,
  setOuterPrice: (payload) => set({ outerPrice: payload }),
}))

export default useUserStore
