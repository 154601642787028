import axios from 'axios'
import { localstorageKeys } from '@/interfaces/localstorageKeys'
import localforage from 'localforage'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

instance.interceptors.request.use(
  async function (config) {
    const accessToken = await localforage.getItem(localstorageKeys.AccessToken_Nudex)
    if (!config.url.startsWith('/api/v1/login/user/token')) {
      config.headers.Authorization = accessToken || '0'
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    if (response?.data?.code === 200) {
      return response.data.data || true
    }

    return Promise.reject({
      code: response.data.code,
      msg: response.data.msg.replace(';', ''),
    })
  },
  (error) => {
    return error.response.data
  },
)

export default instance
