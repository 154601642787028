import BigNumber from 'bignumber.js'
import { nuvo } from '@/utils/nuvo'
import { useUserStore, parseToken } from '@/models/useUserStore'
import { isNil } from 'lodash/lang'
function formatPriceUI(value, pairId, decimals = 18) {
  const _decimals = isNil(decimals) ? 18 : decimals
  if (value === null || value === undefined) {
    return '-'
  }
  const formattedValue = nuvo.formatUnits(value, _decimals)
  const bn = new BigNumber(formattedValue)

  if (bn.isNaN() || bn.isLessThan(0.00000001)) {
    return '0'
  }

  let digit = 2
  let num
  if (pairId.includes('/')) {
    const userStore = useUserStore.getState()
    digit = userStore.getPairDig(pairId) || digit
  }
  //处理法币价格
  else {
    const tokenDigit = new BigNumber(parseToken(pairId).decimals)
    if (tokenDigit.isLessThanOrEqualTo(5)) {
      digit = Number(tokenDigit)
    } else {
      digit = 6
    }
  }
  // console.log('🚀pairId', pairId, parseToken(pairId).decimals)

  // Determine the precision based on the digit parameter
  // if (bn.isGreaterThanOrEqualTo(100000000)) {
  //   num = bn.toFixed(0, BigNumber.ROUND_DOWN)
  // } else if (bn.isGreaterThanOrEqualTo(100)) {
  //   num = bn.toFixed(2, BigNumber.ROUND_DOWN)
  // } else {
  //   num = bn.toFixed(digit, BigNumber.ROUND_DOWN)
  // }
  num = bn.toFixed(digit, BigNumber.ROUND_DOWN)
  // Remove trailing zeros but keep decimal zeros
  if (num.includes('.')) {
    num = num.replace(/(\.\d*[1-9])0+$|\.0*$/, '$1')
  }

  // Ensure the number has the correct number of decimal places by padding with zeros if necessary
  //不足时补足0
  // if (num.includes('.')) {
  //   const [intPart, decPart] = num.split('.')
  //   num = `${intPart}.${decPart.padEnd(digit, '0')}`
  // } else if (digit > 0) {
  //   num = `${num}.${'0'.repeat(digit)}`
  // }

  // Apply thousand separator
  num = addThousandSeparator(num)

  return num
}

function addThousandSeparator(num) {
  const parts = num.split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export default formatPriceUI
