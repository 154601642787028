export function addFullscreen(charRef, fullContainer) {
  if (charRef.current) {
    let btn
    try {
      btn = charRef.current.createButton({
        align: 'right',
      })
      btn.className = 'group-3uonVBsm'
      btn.innerHTML = `
        <div data-role="button" class="button-2YcRd2gv button-2Vpz_LXc apply-common-tooltip isInteractive-2Vpz_LXc">
          <span class="icon-2Vpz_LXc">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
              <g fill="currentColor">
                <path d="M21 7v4h1V6h-5v1z"></path>
                <path d="M16.854 11.854l5-5-.708-.708-5 5zM7 7v4H6V6h5v1z"></path>
                <path d="M11.146 11.854l-5-5 .708-.708 5 5zM21 21v-4h1v5h-5v-1z"></path>
                <path d="M16.854 16.146l5 5-.708.708-5-5z"></path>
                <g>
                  <path d="M7 21v-4H6v5h5v-1z"></path>
                  <path d="M11.146 16.146l-5 5 .708.708 5-5z"></path>
                </g>
              </g>
            </svg>
          </span>
        </div>
      `
      btn.addEventListener('click', () => {
        Boolean(getFullElement()) ? exitFullscreen() : showFullscreen(fullContainer)
      })
    } catch (e) {
      console.log('--error--', e)
    }
  }
}
export function showFullscreen(dom) {
  try {
    if (dom.requestFullscreen) {
      dom.requestFullscreen()
    } else if (dom.webkitRequestFullscreen) {
      dom.webkitRequestFullscreen()
    } else if (dom.mozRequestFullScreen) {
      dom.mozRequestFullScreen()
    } else if (dom.msRequestFullscreen) {
      dom.msRequestFullscreen()
    }
  } catch (e) {
    console.log('--error--', e)
  }
}
export function exitFullscreen() {
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  ) {
    // 退出全屏方法根据浏览器兼容性选择
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  }
}
export function getFullElement() {
  return (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  )
}
