import axios from 'axios'
// import { localstorageKeys } from '@/interfaces/localstorageKeys'
// import localforage from 'localforage'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_SCRIPTIONS,
})

// !!scriptions api doesn't need authorization
// instance.interceptors.request.use(
//   async function (config) {
//     const nuvoToken = (await localforage.getItem(localstorageKeys.AccessToken_Nuvo)) || {}
//     const accessToken = nuvoToken.accessToken
//     if (accessToken) {
//       config.headers.Authorization = accessToken
//       config.headers['access-token'] = accessToken
//     }

//     return config
//   },
//   function (error) {
//     return Promise.reject(error)
//   },
// )

instance.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default instance

