import './index.scss'
import Logo from '../../assets/img/Logo.svg'

const IpLimit = () => {
  return (
    <div id="Ip-Limit">
      <div className="w-full h-full relative flex flex-col justify-center items-center">
        <img
          className="w-[1400px] absolute top-0 z-20 bg-ip"
          src="/img/landingpage/ip-bg.png"
          alt="logo"
        />
        <img className="w-[186px] z-30" src={Logo} alt="logo" />
        <div className="text-[#fff] text-[32px] font-bold mt-[60px] mb-[28px] z-30">
          Services Unavailable
        </div>
        <div className="text-[#fff] w-[526px] text-center text-[16px] text-[rgba(255,255,255,0.6)] z-30">
          Based on your IP address, we currently do not provide services in your country or region
          due to local laws, regulations, or policies. We apologize for any inconvenience this may
          cause.
        </div>
      </div>
    </div>
  )
}

export default IpLimit
