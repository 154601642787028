import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_INDEXER,
})

instance.interceptors.request.use(
    async function (config) {
        return config
    },
    function (error) {
        return Promise.reject(error)
    },
)

instance.interceptors.response.use(
    (response) => {
      if (response?.status === 200) {
        return response.data
      }
    },
    (error) => {
      return Promise.reject(error)
    },
  )

export default instance
