export const TYPE_symbolSelect = ['favorite_tab', 'all_tab', 'USDT_tab', 'USDC_tab']
export let ENUM_symbolSelect
;(function (ENUM_symbolSelect) {
  ENUM_symbolSelect[(ENUM_symbolSelect['favorite_tab'] = 0)] = 'favorite_tab'
  ENUM_symbolSelect[(ENUM_symbolSelect['all_tab'] = 1)] = 'all_tab'
  ENUM_symbolSelect[(ENUM_symbolSelect['USDT_tab'] = 2)] = 'USDT_tab'
  ENUM_symbolSelect[(ENUM_symbolSelect['USDC_tab'] = 3)] = 'USDC_tab'
})(ENUM_symbolSelect || (ENUM_symbolSelect = {}))
export const TYPE_marketSymbolSelect = ['all_tab', 'USDT_tab', 'USDC_tab']
export let ENUM_marketSymbolSelect
;(function (ENUM_marketSymbolSelect) {
  ENUM_marketSymbolSelect[(ENUM_marketSymbolSelect['all_tab'] = 0)] = 'all_tab'
  ENUM_marketSymbolSelect[(ENUM_marketSymbolSelect['USDT_tab'] = 1)] = 'USDT_tab'
  ENUM_marketSymbolSelect[(ENUM_marketSymbolSelect['USDC_tab'] = 2)] = 'USDC_tab'
})(ENUM_marketSymbolSelect || (ENUM_marketSymbolSelect = {}))
export const TYPE_setting = ['future_setting', 'layout_setting']
export let ENUM_setting
;(function (ENUM_setting) {
  ENUM_setting[(ENUM_setting['future'] = 0)] = 'future'
  ENUM_setting[(ENUM_setting['layout'] = 1)] = 'layout'
})(ENUM_setting || (ENUM_setting = {}))

// export const TYPE_chart = ['kline', 'depth', 'tabFunding', 'details', 'riskRate']
export const TYPE_chart = ['kline', 'depth']

export let ENUM_chart
;(function (ENUM_chart) {
  ENUM_chart[(ENUM_chart['kline'] = 0)] = 'kline'
  ENUM_chart[(ENUM_chart['depth'] = 1)] = 'depth'
  ENUM_chart[(ENUM_chart['funding'] = 2)] = 'funding'
  ENUM_chart[(ENUM_chart['details'] = 3)] = 'details'
  ENUM_chart[(ENUM_chart['riskRate'] = 4)] = 'riskRate'
})(ENUM_chart || (ENUM_chart = {}))
export const TYPE_history = ['orderBook', 'recentTrades']
export let ENUM_history
;(function (ENUM_history) {
  ENUM_history[(ENUM_history['orderBook'] = 0)] = 'orderBook'
  ENUM_history[(ENUM_history['recentTrades'] = 1)] = 'recentTrades'
})(ENUM_history || (ENUM_history = {}))
export const TYPE_order = ['limit', 'market', 'condition']
export let ENUM_order
;(function (ENUM_order) {
  ENUM_order[(ENUM_order['limit'] = 0)] = 'limit'
  ENUM_order[(ENUM_order['market'] = 1)] = 'market'
  ENUM_order[(ENUM_order['condition'] = 2)] = 'condition'
})(ENUM_order || (ENUM_order = {}))
export let ENUM_conditionOrderType
;(function (ENUM_conditionOrderType) {
  ENUM_conditionOrderType['conditionalMarket'] = 'conditionalMarket'
  ENUM_conditionOrderType['conditionalLimit'] = 'conditionalLimit'
})(ENUM_conditionOrderType || (ENUM_conditionOrderType = {}))
export const TYPE_positions = [
  'positions',
  'closePnL',
  'active',
  'conditional',
  'filled',
  'tradeHistory',
]
export let ENUM_positions
;(function (ENUM_positions) {
  ENUM_positions[(ENUM_positions['positions'] = 0)] = 'positions'
  ENUM_positions[(ENUM_positions['closePnL'] = 1)] = 'closePnL'
  ENUM_positions[(ENUM_positions['active'] = 2)] = 'active'
  ENUM_positions[(ENUM_positions['conditional'] = 3)] = 'conditional'
  ENUM_positions[(ENUM_positions['filled'] = 4)] = 'filled'
  ENUM_positions[(ENUM_positions['tradeHistory'] = 5)] = 'tradeHistory'
})(ENUM_positions || (ENUM_positions = {}))
export const CONST_historyTrade = ['price', 'amount', 'time']
export const CONST_historyOrderBook = ['price', 'amount', 'total']
export const TYPE_orderBook = ['all', 'buy', 'sell']
export let ENUM_orderBookType
;(function (ENUM_orderBookType) {
  ENUM_orderBookType[(ENUM_orderBookType['all'] = 0)] = 'all'
  ENUM_orderBookType[(ENUM_orderBookType['buy'] = 1)] = 'buy'
  ENUM_orderBookType[(ENUM_orderBookType['sell'] = 2)] = 'sell'
})(ENUM_orderBookType || (ENUM_orderBookType = {}))
export const CONST_rationSlice = 4
export const TYPE_side = {
  buy: 'buy',
  sell: 'sell',
}
export const TYPE_price = [
  {
    label: 'typeLastPrice',
    value: 'lastPrice',
  },
  {
    label: 'typeLastIndex',
    value: 'lastIndex',
  },
  {
    label: 'typeLastMark',
    value: 'lastMark',
  },
]
export const TYPE_conditionTriggerPrice = [
  {
    label: 'oraclePrice',
    value: 'ORACLE',
  },
  {
    label: 'indexPrice',
    value: 'INDEX',
  },
  {
    label: 'lastPrice',
    value: 'MARKET',
  },
]
export const conditionTriggerPriceTypeMap = {
  ORACLE: 'oraclePrice',
  INDEX: 'indexPrice',
  MARKET: 'lastPrice',
}
export function getTriggerType(type) {
  return conditionTriggerPriceTypeMap[type]
}
export const TYPE_conditionType = [
  {
    label: 'conditionalMarket',
    value: 'conditionalMarket',
  },
  {
    label: 'conditionalLimit',
    value: 'conditionalLimit',
  },
]
export const TYPE_timeInForce = [
  {
    label: 'typeGTC',
    value: 'GOOD_TIL_CANCEL',
  },
  {
    label: 'typeIOC',
    value: 'IMMEDIATE_OR_CANCEL',
  },
  {
    label: 'typeFOK',
    value: 'FILL_OR_KILL',
  },
]
export let ENUM_conditionalOrderType
;(function (ENUM_conditionalOrderType) {
  ENUM_conditionalOrderType['STOP_MARKET'] = 'STOP_MARKET'
  ENUM_conditionalOrderType['TAKE_PROFIT_MARKET'] = 'TAKE_PROFIT_MARKET'
  ENUM_conditionalOrderType['STOP_LIMIT'] = 'STOP_LIMIT'
  ENUM_conditionalOrderType['TAKE_PROFIT_LIMIT'] = 'TAKE_PROFIT_LIMIT'
})(ENUM_conditionalOrderType || (ENUM_conditionalOrderType = {}))
export let ENUM_fundingRateType
;(function (ENUM_fundingRateType) {
  ENUM_fundingRateType['1HRatio'] = '1HRatio'
  ENUM_fundingRateType['8HRatio'] = '8HRatio'
  ENUM_fundingRateType['annualized'] = 'annualized'
})(ENUM_fundingRateType || (ENUM_fundingRateType = {}))
export const CONST_conditionalOrderType = [
  'STOP_MARKET',
  'TAKE_PROFIT_MARKET',
  'STOP_LIMIT',
  'TAKE_PROFIT_LIMIT',
]
export const TYPE_keyboard = {
  ENTER: 13,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
}
export const KLINE_LOCAL_KEY = 'l2dex.tv.setting'
// 离开多少时间后 取消订阅 单位毫秒
export const NEED_UNSUBSCRIBE_DURATION = 180000 // 3分钟
// 下单常量
/***************************************************************************/
export const TYPE_orderSide = {
  buy: 'BUY',
  sell: 'SELL',
}
export const TYPE_orderType = {
  limit: 'LIMIT',
  market: 'MARKET',
}
// WS topic
/***************************************************************************/
export const WS_TOPIC_candle = (symbol, interval) => {
  return symbol ? `candle.${interval}.${symbol}` : ''
}
export const WS_TOPIC_instrumentInfo = (symbol, speed) => {
  return symbol ? `instrumentInfo.${speed || 'H'}.${symbol}` : ''
}
export const WS_TOPIC_instrumentInfoAll = () => {
  return 'instrumentInfo.all'
}
export const WS_TOPIC_orderBook = (symbol) => {
  return symbol ? `orderBook.H.${symbol}` : ''
}
export const WS_TOPIC_depth = (symbol, speed) => {
  return symbol ? `orderBook.${speed || 'H'}.${symbol}` : ''
}
export const WS_TOPIC_recentTrades = (symbol, speed) => {
  return symbol ? `recentlyTrade.${speed || 'H'}.${symbol}` : ''
}
export const WS_TOPIC_private = 'order.list.openOrder'
export const DEFAULT_TXT = '-'
export const TRADING_VIEW_LOCALE_MAP = {
  'en-US': 'en',
  'zh-CN': 'zh',
  'ja-JP': 'ja',
  'ko-KR': 'ko',
  'ru-RU': 'ru',
  'vi-VN': 'vi',
  'zh-TW': 'zh_TW',
  'es-ES': 'es',
  'tr-TR': 'tr',
  'pt-BR': 'pt',
  'fr-FR': 'fr',
  'th-TH': 'th',
  'id-ID': 'id_ID',
  'uk-UA': 'en',
  'kk-KZ': 'en',
  'hi-IN': 'en',
  'fil-PH': 'en',
}
export const localOrderTypeKey = 'trade.order.type' // 订单类型存储
export const localConditionOrderTypeKey = 'trade.order.condition.type' // 订单条件单类型存储
export const localShowOrderEqualValKey = 'trade.order.equal.val' // 显示等价价值输入框
export const localShowAssetsKey = 'trade.assets.show' // 显示总资产内容
export const themeTypeList = ['v1', 'classical']
export const themeColorList = ['normal', 'reverse']
const v1Long = '#00FFD1'
const v1Short = '#F15D54'
const classicalLong = 'rgba(0, 255, 209, 0.2)'
const classicalShort = 'rgba(241, 93, 84, 0.08)'
export const longShortColorMap = {
  'v1-normal': {
    long: v1Long,
    short: v1Short,
  },
  'v1-reverse': {
    long: v1Short,
    short: v1Long,
  },
  'classical-normal': {
    long: classicalLong,
    short: classicalShort,
  },
  'classical-reverse': {
    long: classicalShort,
    short: classicalLong,
  },
}
