const local = '/api/v1'
const localV2 = '/api/v2'
const tradeUrl = {
  // symbols: `${local}/symbols`,
  symbols: `${localV2}/symbols`,
  klines: `${local}/klines`,
  tickers: `${local}/tickers`,
  depth: `${local}/depth`,
  trades: `${local}/trades`,
}
const urls = {
  resend: `${local}/send-verification-email`,
  user: `${local}/user`,
  transferLimit: `${localV2}/transfer-limit`,
  realtime_private: `/realtime_private`,
  onboarding: `${localV2}/onboarding`,
  checkUserExist: `${local}/check-user-exist`,
  tokenDetail: `${local}/token-detail`,
  checkUsernameExist: `${local}/check-username-exist`,
  getNonce: `${local}/generate-nonce`,
  withdrawals: `${localV2}/create-withdrawal-to-address`,
  fastWithdraw: `${localV2}/fast-withdraw`,
  crossWithdraw: `${localV2}/cross-chain-withdraw`,
  fee: `${local}/uncommon-withdraw-fee`,
  deposit_test: `${local}/deposit-test`,
  accounts: `${localV2}/account`,
  createOrder: `${localV2}/create-order`,
  time: `${local}/time`,
  positions: `${local}/accounts/positions`,
  cancelOrder: `${localV2}/delete-order`,
  cancelAllOrder: `${localV2}/delete-open-orders`,
  getOrderFills: `${localV2}/order-fills`,
  markNotifyRead: `${local}/mark-notify-read`,
  setLevel: `${localV2}/set-initial-margin-rate`,
  transfers: `${localV2}/transfers`,
  openOrders: `${localV2}/open-orders`,
  historicalPNL: `${localV2}/historical-pnl`,
  historyOrders: `${localV2}/history-orders`,
  fills: `${localV2}/fills`,
  withdrawList: `${local}/withdraw-list`,
  getWaitingWithdrawValue: `${local}/waiting-withdraw-value`,
  notifyList: `${local}/notify-list`,
  getTransfer: `${local}/get-transfer`,
  markAllNotifyRead: `${local}/mark-all-notify-read`,
  updateUser: `${local}/modify-user`,
  yesterdayPnL: `${local}/yesterday-pnl`,
  todayPnL: `${local}/today-pnl-unpnl`,
  historyValue: `${localV2}/history-value`,
  adsBanner: `${local}/ads-banner`,
  historyFunding: `${localV2}/history-funding`,
  faucet: `${local}/faucet`,
  verify: `${local}/verify-email`,
  userFunding: `${localV2}/funding`,
  getWorstPrice: `${localV2}/get-worst-price`,
  updateLanguage: `${local}/update-language`,
  getDeposit: `${local}/get-deposit`,
  checkIp: `${local}/country-blacklister/eval`,
  getWithdrawBalance: `${localV2}/can-withdrawal-balance`,
  greyStatus: `${local}/gray-status`,
  // 交易胜率
  winRateSymbols: `${localV2}/win-rate-symbol`,
  winRateDays: `${localV2}/win-rate-days`,
  winRateTrades: `${localV2}/win-rate-trades`,
  stableTokenPrice: `${local}/stable-token-price`,
  platformTradeStatistics: `${localV2}/platform-trade-statistics`,
  lastDepositChain: `${localV2}/last-deposit-chain`,
  symbolPriceSource: `${local}/symbol-price-source`,
  // 收藏币对列表
  addFavorite: `${local}/add-favorite`,
  removeFavorite: `${local}/remove-favorite`,
  allFavorites: `${local}/all-favorites`,
  //导出表单
  exportHistory: `${localV2}/export-history-orders`,
  exportClosePnL: `${localV2}/export-position-transaction`,
  exportFills: `${localV2}/export-order-fills`,
  exportFounding: `${localV2}/export-funding-fee`,
  // api页面
  allApiKeys: `${local}/all-api-keys`,
  generateApiKey: `${local}/generate-api-key`,
  deleteApiKey: `${local}/delete-api-key`,
  updateApiKey: `${local}/update-api-key`,
  // 用户代理商体验金领取条件
  agentExperienceMoney: `${local}/reward/Verify-agent-experience-money`,
  // 领取体验金
  claimAgentExperienceMoney: `${local}/reward/receive-experience-money`,
  // 回收体验金
  recycleExperienceMoney: `${localV2}/reward/recycle-experience-money`,
  // 代理商大赛一级导航栏显示
  agentMVPtitle: `${local}/reward/query-reward-agent-list`,
  // 交易页广告浮窗
  advertiseWindow: `${local}/available-banner-list`,
  ...tradeUrl,
}
export default urls
