export default class XLocalStore {
  constructor(key) {
    this.globalStoreMap = null
    this.localKey = ''
    this.setStoreItem = (key, value) => {
      this.initStore()
      if (this.globalStoreMap) {
        this.globalStoreMap[key] = value
        window.localStorage.setItem(this.localKey, JSON.stringify(this.globalStoreMap))
      }
    }
    this.getStoreItem = (key) => {
      this.initStore()

      return this.globalStoreMap[key]
    }
    this.rmStoreItem = (key) => {
      this.initStore()
      if (this.globalStoreMap && this.globalStoreMap[key]) {
        delete this.globalStoreMap[key]
        window.localStorage.setItem(this.localKey, JSON.stringify(this.globalStoreMap))
      }
    }
    this.localKey = key
  }
  initStore() {
    if (!this.globalStoreMap) {
      const localStoreStr = window.localStorage.getItem(this.localKey)
      this.globalStoreMap = localStoreStr ? JSON.parse(localStoreStr) : {}
    }
  }
}
