import BigNumber from 'bignumber.js'
import urls from '@/config/api'
import Clock from '@/components/public/apex/lib/clock'
import { getCurrentConfig } from '@/interfaces/main'
// 重写toFixed 函数，精度后四舍五入
const oldToFixed = window.Number.prototype.toFixed
window.Number.prototype.toFixed = function (precision) {
  const num = +this
  switch (true) {
    case isNaN(num):
      return String(this)
    case precision === undefined:
      return String(Math.round(num))
    case Number(precision) < 0:
      return String(this)
    case num === 0:
      return `0${precision > 0 ? '.' + new Array(Math.ceil(precision)).fill(0).join('') : ''}`
    default:
      const scaleNum = Math.pow(10, Math.ceil(precision))

      return oldToFixed.call(Math.round(num * scaleNum) / scaleNum, Math.ceil(precision))
  }
}
/**
 *
 * @param obj
 * @returns {any}
 */
export function deepCopy(obj) {
  let results = typeof obj === 'object' ? (Array.isArray(obj) ? [] : obj === null ? null : {}) : obj
  if (typeof results === 'object' && results !== null) {
    for (let item in obj) {
      if (obj && obj.hasOwnProperty(item)) {
        results[item] = deepCopy(obj[item])
      }
    }
  }
  return results
}
/**
 *
 * @param millisecond
 * @param formatter
 * @returns {string}
 */
export function formatDateTime(millisecond, formatter) {
  const date = new Date(millisecond)
  const opt = {
    'Y+': date.getFullYear().toString(),
    'm+': (date.getMonth() + 1).toString(),
    'd+': date.getDate().toString(),
    'H+': date.getHours().toString(),
    'M+': date.getMinutes().toString(),
    'S+': date.getSeconds().toString(), // 秒
  }
  for (let k in opt) {
    let reg = new RegExp('(' + k + ')').exec(formatter)
    if (reg) {
      formatter = formatter.replace(
        reg[1],
        reg[1].length == 1 ? opt[k] : opt[k].padStart(reg[1].length, '0'),
      )
    }
  }
  return formatter
}
export function formatSeconds(seconds) {
  seconds = Number(seconds)
  const tempObj = {
    day: '00',
    hour: '00',
    minute: '00',
    second: '00',
  }
  if (seconds && seconds > 0) {
    const day = Math.floor(seconds / 86400)
    tempObj.day = (day > 9 ? '' : '0') + day
    seconds = seconds % 86400
    const hour = Math.floor(seconds / 3600)
    tempObj.hour = (hour > 9 ? '' : '0') + hour
    seconds = seconds % 3600
    const minute = Math.floor(seconds / 60)
    tempObj.minute = (minute > 9 ? '' : '0') + minute
    const second = seconds % 60
    tempObj.second = (second > 9 ? '' : '0') + second
  }
  return tempObj
}
function compare(key, type) {
  return function sortGen(m, n) {
    const a = isNaN(Number(m[key])) ? m[key]?.toUpperCase() : Number(m[key])
    const b = isNaN(Number(n[key])) ? n[key]?.toUpperCase() : Number(n[key])
    if (a < b) {
      return type === 'up' ? -1 : 1
    }
    if (a > b) {
      return type === 'up' ? 1 : -1
    }
    return 0
  }
}
/**
 *
 * @param list
 * @param key
 * @param type
 * @returns {any}
 */
export function sortObjectArrayByKey(list, key, type) {
  const tempList = deepCopy(list)
  return tempList.sort(compare(key, type || 'up'))
}
export function findIndexByKey(list, key, value) {
  let tempIdx = -1
  list.some((item, idx) => {
    if (item[key] === value) {
      tempIdx = idx
      return true
    }
  })
  return tempIdx
}
// 数字处理
/**********************************************************************/
/**
 * 科学计数转普通数字
 * @param num
 * @returns {string}
 */
export function toNonExponential(num) {
  const tempResult = Number(num)
  if (isNaN(tempResult)) {
    return String(num)
  }
  const matches = tempResult.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/)
  return tempResult.toFixed(
    Math.max(0, (matches ? matches[1] : '').length - Number(matches ? matches[2] : 0)),
  )
}
/**
 * 获取数值、数字字符串 精度
 * @param num
 * @returns {number}
 */
export function getNumberPrecision(num) {
  const valStrList = String(num).split('.')
  return valStrList.length <= 2 ? (valStrList[1] ? valStrList[1].length : 0) : 2
}
/**
 * Round by tickSize
 * @param num
 * @param tickSize
 * @param isFloor 是否向下取值，用于计算最大值时，向下取数据、防止溢出
 * @returns {number}
 *
 * toTickSizeNumber(1.58, 0.05) -> 1.60
 * toTickSizeNumber(1.54, 0.05) -> 1.55
 */
export function toTickSizeRoundString(num, tickSize, isFloor) {
  const tempResult = Number(num)
  if (isNaN(tempResult) || tickSize <= 0) {
    return String(num)
  }
  let result
  const tickSizeNum = Number(tickSize)
  if (tickSizeNum >= 1) {
    result = (Math[isFloor ? 'floor' : 'round'](tempResult / tickSizeNum) * tickSizeNum).toFixed(0)
  } else {
    const precision = String(tickSize).length - 2
    result = bigNumberMultiply(
      Math[isFloor ? 'floor' : 'round'](bigNumberMultiply(tempResult, 1 / tickSizeNum)),
      tickSizeNum,
    ).toFixed(precision)
  }
  return Number(result) === 0 ? (tempResult !== 0 ? String(tickSize) : result) : result
}
/**
 * 百分比处理
 * @param num
 * @param precision
 * @returns {number | string}
 */
export function toPercentString(num, precision) {
  const tempResult = Number(num)
  const valPrecision = precision === undefined ? 2 : precision
  return isNaN(tempResult)
    ? (0).toFixed(valPrecision)
    : `${bigNumberMultiply(tempResult, 100).toFixed(valPrecision)}%`
}
/**
 * 普通小数精度处理 截取有效位
 * toPrecisionString(0.001, 2) -> '0.00'
 * toPrecisionString(0.045, 2) -> '0.04'
 * toPrecisionString(0.045, 6) -> '0.045000'
 * toPrecisionString(0.001, 2, 'withMinVal') -> '0.01'
 * @param num
 * @param precision
 * @param withMinVal 是否需要最小值
 * @returns {string}
 */
export function toPrecisionString(num, precision = 2, withMinVal) {
  const tempResult = Number(num)
  const valPrecision = precision === undefined ? 2 : precision
  if (num === undefined) return (0).toFixed(valPrecision)
  const scaleNum = Math.pow(10, Math.ceil(valPrecision))
  const result = String(
    isNaN(tempResult)
      ? num
      : (Math.floor(bigNumberMultiply(tempResult, scaleNum)) / scaleNum).toFixed(valPrecision),
  )
  return Boolean(withMinVal) && Number(result) === 0 ? String(Math.pow(10, -precision)) : result
}
/**
 * 向下 或 向上取精度
 * toPrecisionStringWithType(0.001, 2, 'ceil') -> '0.01'
 * toPrecisionStringWithType(0.045, 2) -> '0.04'
 * toPrecisionStringWithType(0.045, 6) -> '0.045000'
 * @param num
 * @param precision
 * @param type
 */
export function toPrecisionStringWithType(num, precision = 2, type = 'floor') {
  const tempResult = Number(num)
  const valPrecision = precision === undefined ? 2 : precision
  if (isNaN(tempResult)) {
    return (0).toFixed(valPrecision)
  } else {
    const scaleNum = Math.pow(10, valPrecision)
    return (Math[type](bigNumberMultiply(tempResult, scaleNum)) / scaleNum).toFixed(valPrecision)
  }
}
/**
 * 千分化 小数点截取
 * toThousandString(0.004, 2) -> 0.00
 * toThousandString(0.004, 2, 'withMinVal') -> 0.01
 * toThousandString(10001.123, 6) -> 10,001.123000
 * toThousandString(10001.1234567, 6) -> 10,001.123456
 * @param num
 * @param precision
 * @param withMinVal
 * @returns {number | string}
 */
export function toThousandString(num, precision, withMinVal) {
  const tempResult = Number(num)
  const valPrecision = precision === undefined ? 2 : precision
  if (isNaN(tempResult)) {
    return (0).toFixed(valPrecision)
  }
  const scaleNum = Math.pow(10, valPrecision)
  const tempStrList = (Math.floor(bigNumberMultiply(tempResult, scaleNum)) / scaleNum)
    .toFixed(valPrecision)
    .split('.')
  const result =
    tempStrList.length === 2
      ? `${Number(tempStrList[0]).toLocaleString()}.${tempStrList[1]}`
      : Number(tempStrList[0]).toLocaleString()
  return Boolean(withMinVal) && Number(result.replace(/\\,/g, '')) === 0
    ? String(Math.pow(10, -valPrecision))
    : result
}
/**
 * 千分化，小数位为 精度内有效值 位数截取
 * toThousandStringWithValidPrecision(10001.123, 6) -> 10,001.123
 * toThousandStringWithValidPrecision(10001.1234567, 6) -> 10,001.123456
 * @param num
 * @param precision
 */
export function toThousandStringWithValidPrecision(num, precision) {
  const tempResult = Number(num)
  const valPrecision = precision === undefined ? 2 : precision
  if (isNaN(tempResult)) {
    return (0).toFixed(valPrecision)
  }
  const tempStrList = toPrecisionString(tempResult, valPrecision).split('.')
  tempStrList[1] = tempStrList[1].replace(/0+$/, '')
  return !tempStrList.includes('')
    ? `${Number(tempStrList[0]).toLocaleString()}.${tempStrList[1]}`
    : Number(tempStrList[0]).toLocaleString()
}
/**
 * 大数据 添加B M K
 * @param num
 * @param precision
 * @returns {number | string}
 */
export function toLargeUnitString(num, precision) {
  const tempResult = Number(num)
  if (isNaN(tempResult)) {
    return ''
  }
  const tempPrecision = precision === undefined ? 2 : precision
  let result
  switch (true) {
    case tempResult > 1000000000: // B
      result = `${(tempResult / 1000000000).toFixed(tempPrecision)}B`
      break
    case tempResult > 1000000: // M
      result = `${(tempResult / 1000000).toFixed(tempPrecision)}M`
      break
    case tempResult > 1000: // K
      result = `${(tempResult / 1000).toFixed(tempPrecision)}K`
      break
    default:
      result = tempResult.toFixed(tempPrecision)
  }
  return result
}
/**********************************************************************/
// 带精度计算 相乘
export function bigNumberMultiply(...args) {
  switch (true) {
    case !args.length:
      return 0
    case args.length === 1:
      return new BigNumber(args[0]).toNumber()
    default:
      const lastArgs = args.slice(1)
      return lastArgs
        .reduce((previousValue, currentValue) => {
          if (isNaN(currentValue)) {
            console.log(`${args} has isNaN number`)
          }
          return previousValue.multipliedBy(isNaN(currentValue) ? 1 : currentValue)
        }, new BigNumber(args[0]))
        .toNumber()
  }
}
// 带精度计算 相加
export function bigNumberPlus(...args) {
  switch (true) {
    case !args.length:
      return 0
    case args.length === 1:
      return new BigNumber(args[0]).toNumber()
    default:
      const lastArgs = args.slice(1)
      return lastArgs
        .reduce((previousValue, currentValue) => {
          if (isNaN(currentValue)) {
            console.log(`${args} has isNaN number`)
          }
          return previousValue.plus(isNaN(currentValue) ? 0 : currentValue)
        }, new BigNumber(args[0]))
        .toNumber()
  }
}
// 带精度计算 取余
export function bigNumberModulo(numA, numB) {
  const father = new BigNumber(numA)
  return father.modulo(numB).toNumber()
}
/*********************************************************************/
// 替换symbol 名称内的 '-'
export function getSymbolShowName(symbol) {
  return symbol ? symbol.replace('-', '').replace('USDC', 'USD') : ''
}
export function getBaseCoinShowName(baseCoin) {
  return baseCoin ? baseCoin.replace('USDC', 'USD') : ''
}
export function getSymbolByShowName(showName) {
  return showName ? showName.replace(/USD$/, '-USDC').replace(/USDT$/, '-USDT') : ''
}
export function getBaseCoinBySymbolName(symbol) {
  const baseCoin = symbol?.split('-')[1] || getCurrentConfig()
  return baseCoin.toLowerCase()
}
// 金额添加$，同时千分位化
export function armWith$(val, precisioin) {
  const num = Number(val)
  if (isNaN(num)) {
    return String(val)
  }
  return `${num >= 0 ? '' : '-'}$${toThousandString(
    Math.abs(num),
    precisioin === undefined ? 2 : precisioin,
  )}`
}
export function getQuery(name, href) {
  let match = undefined
  let index = href.indexOf('?')
  if (index > -1 && href.match(new RegExp(name, 'i'))) {
    let queryStr = href.slice(index + 1).replace(/#([^?]+)/g, '')
    let matches = queryStr.match(new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i'))
    match = matches && matches[2] ? decodeURIComponent(matches[2]) : undefined
  }
  return match
}
// 获取小数位，分割 字符小数点
export function getPrecision(num) {
  const val = Number(num)
  if (isNaN(val)) {
    return 0
  }
  const strList = String(num).split('.')
  return strList.length === 2 ? strList[1].length : 0
}
export function armColorToRgba(color, opacity = 1) {
  let rgba = color
  if (color.includes('#')) {
    rgba =
      'rgba(' +
      parseInt('0x' + color.slice(1, 3)) +
      ', ' +
      parseInt('0x' + color.slice(3, 5)) +
      ', ' +
      parseInt('0x' + color.slice(5, 7)) +
      ', ' +
      opacity +
      ')'
  } else if (color.includes('rgb(')) {
    rgba = color.split(')')[0] + ', ' + opacity + ')'
  }
  return rgba
}
export function getTimestampByDays(days) {
  const now = new Date()
  now.setMilliseconds(0)
  if (days === 1) {
    const end = now.getTime()
    return {
      startStamp: end - 24 * 3600000,
      endStamp: end,
    }
  }
  now.setHours(23)
  now.setMinutes(59)
  now.setSeconds(59)
  const endStamp = now.getTime()
  return {
    startStamp: new Date(endStamp - (days < 0 ? 0 : days) * 24 * 3600000 + 1000).getTime(),
    endStamp,
  }
}
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
export const genServerTime = async () => {
  if (window?.serverClock && window?.serverClock?.getAdjustedIsoTimestamp) {
    return window?.serverClock?.getAdjustedIsoTimestamp || 0
  }
  // 获取机器时间
  const timeData = await fetch(urls.time)
  const json = await timeData.json()
  const timestamp = json?.data?.time
  const clock = new Clock()
  const timeGap = +timestamp - Date.now()
  clock.setTimestampAdjustment(timeGap)
  window.__serverTimeInfo__ = `timeRes: ${timestamp} reqTimestamp: ${Date.now()} timeGap: ${timeGap}`
  window.serverClock = clock
  return clock?.getAdjustedIsoTimestamp
}
export function maskTxt(txt, options) {
  const { length = 10, word = '.' } = options || {}
  if (txt.length < length * 2) {
    return txt
  } else {
    return `${txt.slice(0, length)}${new Array(6).fill(word).join('')}${txt.slice(
      txt.length - length,
    )}`
  }
}
export function capitalizeFirstLetter(txt) {
  if (typeof txt === 'string' && txt.length) {
    return txt.charAt(0).toUpperCase() + txt.slice(1)
  }
  return txt
}
