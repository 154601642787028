import StretchToast from '@/components/trade/components/depositToast'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useLanguage } from '@/language'
import { formatUnits } from '@ethersproject/units'
import { useWeb3React } from '@web3-react/core'
import { useEffect } from 'react'
import ConfirmationToast from '@/components/deposit/components/ConfirmationToast'
import { CHAIN_INFO, ifCountdownMode } from '@/components/public/apex/constants'
import { ethers } from 'ethers'
const CROSS_CHECK = true
const Updater = () => {
  const [depositTx, setDepositTx] = useLocalStorage('deposit-tx', {})
  const { account, library, chainId } = useWeb3React()
  const multiLanguage = useLanguage()
  const getTx = async (tx, _chainId) => {
    if (!tx || !chainId) return { res: null, library }
    let p = library
    if (chainId !== _chainId) {
      p = new ethers.JsonRpcProvider(CHAIN_INFO[_chainId].rpc)
    }
    const res = await p.getTransaction(tx)
    return { res, library: p }
  }
  const getTxTimeStamp = async (tx, _chainId) => {
    if (!tx || !chainId) return { res: null, library }
    let p = library
    if (chainId !== _chainId) {
      p = new ethers.JsonRpcProvider(CHAIN_INFO[_chainId].rpc)
    }
    const _tx = await p.getTransaction(tx)
    const res = await _tx.wait()
    const block = await p.getBlock(res?.blockNumber)
    return { res: block?.timestamp, library: p }
  }
  const removeArr = ({ copiedTx, account, i }) => {
    copiedTx[account].splice(i, 1)
    setDepositTx(copiedTx)
  }
  const batchFetch = async () => {
    if (!account || !depositTx || !depositTx[account]) return
    const len = depositTx[account].length
    const copiedTx = JSON.parse(JSON.stringify(depositTx))
    for (let i = 0; i < len; i++) {
      const tx = depositTx[account][i]
      let latestBlockTimestamp = tx?.blockTime
      if (!tx.blockTime) {
        const t = await getTxTimeStamp(tx?.hash, tx?.chainId)
        latestBlockTimestamp = (t?.res + tx?.secPerBlock * Number(tx?.max)) * 1000
      }
      if (chainId !== tx?.chainId && !CROSS_CHECK) {
        // 关闭非当前链的弹窗
        const toastCtr = document.getElementById(tx?.hash)
        if (toastCtr) {
          toastCtr.remove()
        }
        break
      }
      if (ifCountdownMode) {
        if (latestBlockTimestamp > +new Date()) {
          // 检查弹窗是否已经存在
          const ifExist = document.getElementById(tx?.hash)
          if (!ifExist) {
            const handleClose = StretchToast.show(
              {
                type: 'staticLoading',
                title: multiLanguage('depositing'),
                txt: (
                  <ConfirmationToast
                    provider={null}
                    max={tx?.max}
                    tx={tx?.hash}
                    usdcValue={`${formatUnits(tx?.amount, tx?.decimals)}`}
                    symbol={tx?.symbol}
                    chainId={tx?.chainId}
                    blockTime={latestBlockTimestamp}
                    maxSec={tx?.maxSec}
                    removeArr={() =>
                      removeArr({
                        copiedTx,
                        account,
                        i,
                      })
                    }
                  />
                ),
              },
              {
                duration: 300000,
                id: tx?.hash,
              },
            )
          }
        } else {
          // 删除
          copiedTx[account].splice(i, 1)
          setDepositTx(copiedTx)
        }
      } else {
        const { res, library } = await getTx(tx?.hash, tx?.chainId)
        // const status = await res.wait();
        if (res?.confirmations <= tx?.max) {
          // 检查弹窗是否已经存在
          const ifExist = document.getElementById(tx?.hash)
          if (!ifExist) {
            const handleClose = StretchToast.show(
              {
                type: 'staticLoading',
                title: multiLanguage('depositing'),
                txt: (
                  <ConfirmationToast
                    provider={library}
                    max={tx?.max}
                    tx={tx?.hash}
                    usdcValue={tx?.amount}
                    symbol={tx?.symbol}
                    chainId={tx?.chainId}
                    blockTime={tx?.blockTime}
                    maxSec={tx?.maxSec}
                    removeArr={() =>
                      removeArr({
                        copiedTx,
                        account,
                        i,
                      })
                    }
                  />
                ),
              },
              {
                duration: 300000,
                id: tx?.hash,
              },
            )
          }
        } else {
          // 删除
          copiedTx[account].splice(i, 1)
          setDepositTx(copiedTx)
        }
      }
    }
  }
  useEffect(() => {
    if (!account || !chainId) return
    batchFetch()
  }, [account, chainId])
  return null
}
export default Updater
