import { isProd } from '@/utils/env'
export const ifOdyssey = false
export const ifCountdownMode = false
export const INFURA_KEY = '9aa3d95b3bc440fa88ea12eaa4456161'
export const biconomyKey = {
  // '1': 'oMuReokRP.7e12ee4c-2d1b-4ad0-a072-63d8d4e1733b',
  5: 'IZfGOXJkn.a4c0849e-d50b-4fa1-9aec-0a0ab38f2b2b',
  56: 'GrZn535fk.0b516e77-5d12-4906-a428-a19698b321e3',
  137: 'UXOATu8i_.bd00c504-4ae7-4a53-b2f4-8f5cc5f208b7',
  42161: '53ljOXLD_.b206f5cf-35d3-4e18-b4eb-d78ecc2748bc',
}
export const gasLessChain = Object.keys(biconomyKey).map((i) => +i)
export var SupportedChainId
;(function (SupportedChainId) {
  SupportedChainId[(SupportedChainId['MAINNET'] = 1)] = 'MAINNET'
  SupportedChainId[(SupportedChainId['SEPOLIA'] = 11155111)] = 'SEPOLIA'
  SupportedChainId[(SupportedChainId['BNB'] = 56)] = 'BNB'
  SupportedChainId[(SupportedChainId['BNB_TEST'] = 97)] = 'BNB_TEST'
  SupportedChainId[(SupportedChainId['POLYGON'] = 137)] = 'POLYGON'
  SupportedChainId[(SupportedChainId['POLYGON_MUMBAI'] = 80001)] = 'POLYGON_MUMBAI'
  SupportedChainId[(SupportedChainId['ARBITRUM_ONE'] = 42161)] = 'ARBITRUM_ONE'
  SupportedChainId[(SupportedChainId['ARBITRUM_RINKEBY'] = 421611)] = 'ARBITRUM_RINKEBY'
  SupportedChainId[(SupportedChainId['Arbitrum_Goerli'] = 421613)] = 'Arbitrum_Goerli'
  SupportedChainId[(SupportedChainId['Optimism'] = 10)] = 'Optimism'
  SupportedChainId[(SupportedChainId['Optimism_Goerli'] = 420)] = 'Optimism_Goerli'
  SupportedChainId[(SupportedChainId['AVAX'] = 43114)] = 'AVAX'
  SupportedChainId[(SupportedChainId['AVAX_FUJI'] = 43113)] = 'AVAX_FUJI'
  SupportedChainId[(SupportedChainId['MANTLE'] = 5000)] = 'MANTLE'
  SupportedChainId[(SupportedChainId['MANTLE_TESTNET'] = 5001)] = 'MANTLE_TESTNET'
})(SupportedChainId || (SupportedChainId = {}))
export const DEFAULT_CHAIN_ID = isProd ? SupportedChainId.MAINNET : SupportedChainId.SEPOLIA
export const Arbi = isProd ? SupportedChainId.ARBITRUM_ONE : SupportedChainId?.Arbitrum_Goerli
export const rpcUrl = {
  [SupportedChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.SEPOLIA]: `https://rpc.sepolia.org`,
  [SupportedChainId.BNB]: 'https://bsc-dataseed4.ninicoin.io',
  [SupportedChainId.BNB_TEST]: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  [SupportedChainId.POLYGON]: 'https://rpc-mainnet.maticvigil.com',
  [SupportedChainId.POLYGON_MUMBAI]:
    'https://naughty-blackwell:waffle-sprawl-math-used-ripple-snarl@nd-311-035-380.p2pify.com',
  [SupportedChainId.ARBITRUM_ONE]: 'https://arb1.arbitrum.io/rpc',
  [SupportedChainId.Arbitrum_Goerli]: 'https://goerli-rollup.arbitrum.io/rpc',
  [SupportedChainId.Optimism]: 'https://mainnet.optimism.io',
  [SupportedChainId.Optimism_Goerli]: 'https://goerli.optimism.io',
  [SupportedChainId.AVAX]: 'https://avalanche-c-chain.publicnode.com',
  [SupportedChainId.AVAX_FUJI]: 'https://api.avax-test.network/ext/bc/C/rpc',
  [SupportedChainId.MANTLE]: 'https://rpc.mantle.xyz',
}
export const CHAIN_INFO = {
  [SupportedChainId.MAINNET]: {
    rpc: rpcUrl[SupportedChainId.MAINNET],
    explorer: 'https://etherscan.io/',
    chainName: 'Ethereum',
    chain: 'Ethereum',
    chainIconUrl: require('@/assets/img/chainIcon/ethereum.svg').default,
    chainId: SupportedChainId.MAINNET,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    confirmation: 30,
  },
  [SupportedChainId.SEPOLIA]: {
    rpc: rpcUrl[SupportedChainId.SEPOLIA],
    explorer: 'https://goerli.etherscan.io/',
    chainName: 'Goerli - Testnet',
    chain: 'Goerli - Testnet',
    chainIconUrl: require('@/assets/img/chainIcon/ethereum.svg').default,
    chainId: SupportedChainId.SEPOLIA,
    nativeCurrency: { name: 'Görli Ether', symbol: 'görETH', decimals: 18 },
    confirmation: 30,
  },
  [SupportedChainId.BNB]: {
    explorer: 'https://bscscan.com/',
    rpc: rpcUrl[SupportedChainId.BNB],
    chainName: 'BNB Chain',
    chainId: SupportedChainId.BNB,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.BNB_TEST]: {
    rpc: rpcUrl[SupportedChainId.BNB_TEST],
    explorer: 'https://testnet.bscscan.com/',
    chainName: 'BNB Chain - Testnet',
    chainId: SupportedChainId.BNB_TEST,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    confirmation: 15,
  },
  [SupportedChainId.ARBITRUM_ONE]: {
    rpc: rpcUrl[SupportedChainId.ARBITRUM_ONE],
    explorer: 'https://arbiscan.io/',
    chainName: 'Arbitrum',
    chain: 'Arbitrum',
    chainId: SupportedChainId.ARBITRUM_ONE,
    chainIconUrl: 'https://static-pro.apex.exchange/chains/chain_logos/Arbitrum.svg',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.Arbitrum_Goerli]: {
    rpc: rpcUrl[SupportedChainId.Arbitrum_Goerli],
    explorer: 'https://goerli.arbiscan.io/',
    chainName: 'Arbitrum Goerli',
    chain: 'Arbitrum Goerli',
    chainId: SupportedChainId.Arbitrum_Goerli,
    chainIconUrl: 'https://static-pro.apex.exchange/chains/chain_logos/Arbitrum.svg',
    nativeCurrency: {
      name: 'Goerli Arbitrum Ether',
      symbol: 'AGOR',
      decimals: 18,
    },
    confirmation: 15,
  },
  [SupportedChainId.POLYGON]: {
    rpc: rpcUrl[SupportedChainId.POLYGON],
    explorer: 'https://polygonscan.com/',
    chainName: 'Polygon',
    chainId: SupportedChainId.POLYGON,
    nativeCurrency: { name: 'Polygon Matic', symbol: 'MATIC', decimals: 18 },
    confirmation: 15,
  },
  [SupportedChainId.POLYGON_MUMBAI]: {
    rpc: rpcUrl[SupportedChainId.POLYGON_MUMBAI],
    explorer: 'https://mumbai.polygonscan.com/',
    chainName: 'Polygon Mumbai',
    chainId: SupportedChainId.POLYGON_MUMBAI,
    nativeCurrency: {
      name: 'Polygon Mumbai Matic',
      symbol: 'mMATIC',
      decimals: 18,
    },
  },
  [SupportedChainId.Optimism]: {
    rpc: rpcUrl[SupportedChainId.Optimism],
    explorer: 'https://optimistic.etherscan.io',
    chainName: 'Optimism',
    chainId: SupportedChainId.Optimism,
    nativeCurrency: {
      name: 'Optimism',
      symbol: 'OP',
      decimals: 18,
    },
  },
  [SupportedChainId.Optimism_Goerli]: {
    rpc: rpcUrl[SupportedChainId.Optimism_Goerli],
    explorer: 'https://goerli-optimism.etherscan.io/',
    chainName: 'Optimism Goerli',
    chainId: SupportedChainId.Optimism_Goerli,
    nativeCurrency: {
      name: 'Optimism Goerli',
      symbol: 'OP',
      decimals: 18,
    },
  },
  [SupportedChainId.AVAX]: {
    rpc: rpcUrl[SupportedChainId.AVAX],
    explorer: 'https://snowtrace.io/',
    chainName: 'Avalanche C-Chain',
    chainId: SupportedChainId.AVAX,
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
  [SupportedChainId.AVAX_FUJI]: {
    rpc: rpcUrl[SupportedChainId.AVAX_FUJI],
    explorer: 'https://testnet.snowtrace.io/',
    chainName: 'Avalanche Fuji Testnet',
    chainId: SupportedChainId.AVAX_FUJI,
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
  [SupportedChainId.MANTLE]: {
    rpc: rpcUrl[SupportedChainId.MANTLE],
    explorer: 'https://explorer.mantle.xyz/',
    chainName: 'Mantle',
    chainId: SupportedChainId.MANTLE,
    nativeCurrency: {
      name: 'MNT',
      symbol: 'MNT',
      decimals: 18,
    },
  },
}
