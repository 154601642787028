import Icon from '@/components/public/icon'
import { cloneElement } from 'react'
import './style.scss'
const IconMap = {
  error: 'Toast_fail',
  info: 'Toast_info',
  loading: 'Toast_loading',
  success: 'Toast_success',
  staticLoading: 'Toast_static_loading',
}
export function ToastDom({ msg, btn, handleClose }) {
  const { type, title, txt } =
    typeof msg === 'string' || msg === undefined
      ? { type: 'info', title: 'Information', txt: msg || 'Network exception' }
      : msg
  const icon = IconMap[type]
  return (
    <div className="x-toast-ctr toast-ctr-show">
      <Icon name="close_small" className="x-toast-icon-close" onClick={handleClose} />
      <div className="x-toast-info-ctr">
        <Icon name={icon} className={`x-toast-icon ${icon}-icon`} />
        <div className="x-toast-msg">
          <div className="x-toast-title">{title}</div>
          <div className="x-toast-txt">
            {typeof txt === 'string' ? txt : txt && cloneElement(txt, { handleClose })}
          </div>
        </div>
      </div>
      {btn && (
        <div className="x-toast-operate-ctr">
          <div className="x-toast-btn-ctr">{btn}</div>
        </div>
      )}
    </div>
  )
}
