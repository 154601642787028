const routes = {
  verify: '/api/v1/verify-email',
  verifyEmail: '/v1/verify-email',
  verifyEmailBackup: '/api/verify-email',
  learn: '/learn',
  learnDetail: '/learn/detail',
  market: '/market',
  support: '/support',
  trade: '/trade',
  funding: '/funding',
  dashboard: '/dashboard/overview',
  dashmarkets: '/dashboard/overview',
  dashbalance: '/dashboard/overview',
  dashoverview: '/dashboard/overview',
  dashprofile: '/dashboard/profile',
  dashorders: '/dashboard/orders',
  dashhistory: '/dashboard/history',
  dashtransfers: '/dashboard/transfers',
  dashfunding: '/dashboard/funding',
  dashrewards: '/dashboard/rewards',
  dashinscriptions: '/dashboard/inscriptions',
  dashdeposit: '/dashboard/deposit',
  dashwithdraw: '/dashboard/withdraw',
  dashtradingfee: '/dashboard/tradingfee',
  dashsecurity: '/dashboard/security',
  faucet: '/faucet',
  referral: '/referral',
  demo: '/demo',
  landingpage: '/',
  wsTestPage: '/wsTestPage',
}
export default routes
