import scss from './index.module.scss'
import cs from 'classnames'
import Icon from '@/components/public/icon'

const NudexLoading = (props) => {
  const { children, loading } = props
  if (children) {
    return (
      <div>
        {children}
        {loading && (
          <div className={cs(scss.loadingMask, 'w-full h-full flex justify-center items-center')}>
            <Icon name="ToastLoading" className={scss.nudexLoading} />
          </div>
        )}
      </div>
    )
  } else if (!children && loading) {
    return (
      <div className={cs(scss.loadingMask, 'flex justify-center items-center ')}>
        <Icon name="ToastLoading" className={scss.nudexLoading} />
      </div>
    )
  }

  return null
}
export default NudexLoading
