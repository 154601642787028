import instance from './instance'

/**
 * 获取充值地址
 * @param {Object} params
 * @param {string} params.coinType - 币种类型 e.g. 'EVM'
 * @param {boolean} params.addOne - 是否添加一个地址
 * @returns {Promise<string>} address 地址
 */
export async function fetchDepositAddress({ coinType, addOne = false }) {
  return instance
    .get('/api/v1/deposit/address', { params: { coinType, addOne } })
    .then((res) => res?.[0]?.address?.[0])
}

/**
 * 获取充值代币列表
 * @returns {Promise<Array<{coinType: string, chainName: string, tokenSymbol: string, depositMin: number, tokenAddress: string, chainId: number, tokenId: string, type: string, name: string}>>}
 * @property {string} coinType - 币种类型 e.g. 'EVM'
 * @property {boolean} canDep - 是否可以充值
 * @property {boolean} canWd - 是否可以提现
 * @property {string} chainId - 链 ID
 * @property {string} chainLogo - 链logo
 * @property {string} chainName - 链名称 e.g. 'MetisSepolia'
 * @property {number} depositMin - 最小充值金额
 * @property {number} minDepArrivalConfirm - 最小充值到账确认数
 * @property {number} tokenDecimals - 代币精度
 * @property {string} tokenSymbol - 代币符号 e.g. 'NUDEX_USDT_18'
 * @property {string} tokenAddress - 代币地址
 * @property {string} tokenLogo - 代币logo
 * @property {string} tokenType - 代币类型 e.g. 'TOKEN'
 * @property {string} tokenName - 代币名称
 */
export async function fetchDepositTokenList() {
  return instance.get('/api/v1/deposit/token-list')
}
