import { render as Render, createPortal, unmountComponentAtNode as UnMount } from 'react-dom'
import { ToastDom } from './toastDom'
import './style.scss'
import { DialogDom } from '@/components/trade/components/toast/dialogDom'
import { getFullElement } from '@/components/trade/module/chart/tradingview/plugins/full'
const hideAinDuration = 140
class XToast {
  constructor(position = 'top') {
    this.show = (msg, config) => {
      const fullscreenElement = getFullElement()
      let toastArea = document.querySelector('#fullCaseToastArea') // 兼容全屏
      const toastCtr = document.createElement('div')
      toastCtr.className = 'x-toast-outer'
      let show = true
      let timer = null
      const { duration = 3, btn, onClose } = config || {}
      // 定时器清除
      const clearTimer = () => {
        if (timer) {
          clearTimeout(timer)
          timer = null
        }
      }
      // 关闭动画 内存清理
      const handleClose = () => {
        clearTimer()
        show = false
        toastCtr.className = 'x-toast-outer x-toast-hide'
        onClose && onClose()
        setTimeout(() => {
          UnMount(toastCtr)
          toastCtr.onmouseenter = null
          toastCtr.onmouseleave = null
          toastCtr.remove()
        }, hideAinDuration)
      }
      // 自动关闭
      const autoClose = () => {
        timer = setTimeout(() => {
          timer = null
          if (show) {
            handleClose()
          }
        }, duration * 1000)
      }
      // 鼠标划入 清除定时关闭功能
      toastCtr.onmouseenter = () => {
        clearTimer()
      }
      // 鼠标离开入 开启定时关闭功能
      toastCtr.onmouseleave = () => {
        autoClose()
      }
      Render(
        createPortal(<ToastDom msg={msg} btn={btn} handleClose={handleClose} />, toastCtr),
        toastCtr,
      )
      if (fullscreenElement) {
        if (!toastArea) {
          toastArea = document.createElement('div')
          toastArea.className = 'toast-area-bottom'
          toastArea.id = 'fullCaseToastArea'
          fullscreenElement.appendChild(toastArea)
        }
        toastArea?.appendChild(toastCtr)
      } else {
        this.toastArea.appendChild(toastCtr)
      }
      toastCtr.style.height = toastCtr.offsetHeight + 'px'
      autoClose()
    }
    this.success = (msg) => {
      this.show({
        title: 'Success',
        txt: msg,
        type: 'success',
      })
    }
    this.error = (msg) => {
      this.show({
        title: 'Error',
        txt: msg,
        type: 'error',
      })
    }
    // showConfirmDialog = () => {
    this.showConfirmDialog = ({ title, tips, storeKey }) => {
      const dialogCtr = document.createElement('div')
      const handleResult = (result) => {
        realHandle(result)
        if (dialogCtr) {
          dialogCtr.remove()
        }
      }
      let realHandle = () => {}
      Render(
        createPortal(
          <DialogDom title={title} handleResult={handleResult} storeKey={storeKey} tips={tips} />,
          dialogCtr,
        ),
        dialogCtr,
      )
      const fullscreenElement = getFullElement()
      fullscreenElement
        ? fullscreenElement.appendChild(dialogCtr)
        : this.toastArea.appendChild(dialogCtr)
      return new Promise((resolve) => {
        realHandle = resolve
      })
    }
    const id = `xToastArea-${position}`
    const toastArea = document.getElementById(id)
    if (toastArea) {
      this.toastArea = toastArea
    } else {
      this.toastArea = document.createElement('div')
      this.toastArea.id = id
      document.body.appendChild(this.toastArea)
    }
  }
}
const Toast = new XToast()
export const ToastBottom = new XToast('bottom')
export default Toast
